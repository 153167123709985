import React from 'react'
import { Input } from 'reactstrap'
import './TimeInput.scss'


const TimeInput = ({ formik, name }: any) => {
    const _onChange = ({ hours, minutes }: any) => {
        const oldValue = formik.values[name] || '0:0'
        const parts = oldValue
            .split(':')
            .map((v: any) => parseInt(v || 0))

        if (hours !== undefined) {
            parts[0] = hours
        } else {
            parts[1] = minutes
        }

        formik.setFieldValue(name, `${hours ?? parts[0]}:${minutes ?? parts[1]}`)
    }

    const getTimePart = (index: number) => {
        const oldValue = (formik.values[name] || '0:0').split(':')
        return oldValue[index] ?? 0
    }

    return <React.Fragment>
        <div className='d-flex'>
            <Input
                type="number"
                size={3}
                maxLength={3}
                min={0}
                required
                value={getTimePart(0)}
                onChange={(e) => _onChange({
                    hours: e.target.value
                })}
            />
            <span className='time-separator'>:</span>
            <Input
                type="number"
                required
                maxLength={2}
                size={2}
                max={59}
                min={0}
                value={getTimePart(1)}
                onChange={(e) => _onChange({
                    minutes: e.target.value
                })}
            />
        </div>

    </React.Fragment>
}

export default TimeInput