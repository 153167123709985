import CampaignForm from 'Components/Campaigns/CampaignForm'
import CampaignModal from 'Components/Campaigns/CampaignModal'
import BreadCrumb from 'Components/Common/BreadCrumb'
import TemplateForm from 'Components/Templates/TemplateForm'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumb, Container } from 'reactstrap'
import { selectCampaigns, selectProfile, selectTemplate } from 'selectors'
import { createCampaign, createCampaignBudget } from 'slices/campaign/thunk'
import { createTemplate } from 'slices/templates/thunk'


const TemplateCreate = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [values, setValues] = useState<any>({});
    const [file, setFile] = useState<any>(null);
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const template = useSelector(selectTemplate)

    document.title = `Crear mensaje | Burbuxa`

    useEffect(() => {
        if (template.redirToList) {
            navigate('/templates/')
        }
    }, [template.redirToList])

    const _cleanValues = (values: any) => {
        const nValues = { ...values }
        Object.keys(nValues).map((key) => {
            if (nValues[key] === "") {
                nValues[key] = null
            }
        })
        return nValues
    }

    const onSave = (values: any) => {
        const nValues = { ..._cleanValues(values), type: 'CTA' }
        let file;

        if (nValues.file) {
            file = nValues.file
            delete nValues['file']
        } else {
            file = null
        }

        dispatch(
            createTemplate({
                storeId: currentStoreId,
                params: nValues,
                file,
            })
        )
    }

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Crear mensaje" pageTitle="Mensajes" />
                <TemplateForm
                    template={{}}
                    onSubmit={(values: any) => {
                        onSave(values)
                    }}
                />
            </Container>
        </div>
    </React.Fragment>
}

export default TemplateCreate 