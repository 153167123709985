import { Link } from "react-router-dom"
import CampaignStatus from "./CampaignStatus"
import CampaignActionDropdown from "./CampaignActionDropdown"
import { format } from "date-fns"


const CampaignTable = ({ items, loading }: any) => {
    return <div className="table-responsive">
        <table className="table align-middle position-relative table-nowrap">
            <thead className="table-active">
                <tr>
                    <th scope="col" className="text-center">Nombre</th>
                    <th scope="col" className="text-center">Estado</th>
                    <th scope="col" className="text-center">Última edición</th>
                    <th scope="col" className="text-center">Última ejecución</th>
                    <th scope="col" className="text-center">Acciones</th>
                </tr>
            </thead>

            <tbody id="campaign-list">
                {(loading || items == null) && Array(5).fill(0).map((key, index) => <tr key={index}>
                    {Array(5).fill(0).map((_, sIndex) => <td key={sIndex}>
                        <p className="card-text placeholder-glow">
                            <span className="placeholder col-12"></span>
                        </p>
                    </td>)}
                </tr>)}
                {(items || []).map((item: any, key: any) => (<tr key={key}>
                    <td>
                        <div className="text-center text-primary">
                            {item.name}
                        </div>
                    </td>
                    <td className="text-center">
                        <CampaignStatus status={item.status} />
                    </td>
                    <td className="text-center">
                        <span className="text-dark">{item.updated_at ? format(new Date(item.updated_at), 'dd/MM/yyyy') : item.created_at ? format(new Date(item.created_at), 'dd/MM/yyyy') : '-'}</span>
                    </td>
                    <td className="text-center">
                        <span className="text-dark">{item.last_eval_date ? format(new Date(item.last_eval_date), 'dd/MM/yyyy HH:mm') : '-'}</span>
                    </td>
                    <td className="text-center">
                        <CampaignActionDropdown campaign={item} />
                    </td>
                </tr>))}
            </tbody>
        </table>
    </div >
}

export default CampaignTable
