import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectProfile } from 'selectors';
import { deleteCampaign, pauseCampaign, resumeCampaign } from 'slices/campaign/thunk';
import { deleteTemplate } from 'slices/templates/thunk';
import { setCurrentStore } from 'slices/thunks';
import { ICampaign } from 'types/Dashboard';
import TemplatePreviewModal from './TemplatePreviewModal';
import { useState } from 'react';

interface TemplateActionDropdownProps {
    template: any
}

const TemplateActionDropdown = ({ template }: TemplateActionDropdownProps) => {
    const navigate = useNavigate()
    const profile = useSelector(selectProfile)
    const dispatch: any = useDispatch()
    const [show, setShow] = useState(false)

    const toggle = () => {
        setShow(!show)
    }

    const _deleteTemplate = (template: any) => {
        if (profile.currentStoreId) {
            dispatch(
                deleteTemplate({
                    storeId: profile.currentStoreId,
                    templateId: template.id
                })
            )
        }
    }

    return (
        <>
            <ButtonGroup>
                <UncontrolledDropdown>
                    <DropdownToggle tag="button" className='btn btn-light' style={{
                        paddingLeft: 5,
                        paddingRight: 5,
                    }}>
                        <i className="mdi mdi-cog"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        {template.status == 'APPROVED' && <DropdownItem onClick={() => toggle()} className='text-success'><i className="bx bx-mail-send fs-20 align-middle"></i> Envío de prueba</DropdownItem>}
                        <DropdownItem onClick={() => _deleteTemplate(template)} className='text-danger'><span className="mdi mdi-delete-circle-outline"></span> Eliminar plantilla</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </ButtonGroup>
            <TemplatePreviewModal
                templateId={template.id}
                toggle={toggle}
                show={show && !!template}
                onSend={toggle}
            />
        </>
    );
};

export default TemplateActionDropdown;
