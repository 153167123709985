import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    apiDeleteTemplate,
    apiGetTemplates,
    apiPostTemplate,
    apiPostTemplateFile,
    apiPostTemplateTest,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";

export const getTemplates = createAsyncThunk("templates/getTemplates", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetTemplates(storeId, params);
        return response;
    } catch (error) {
        return error;
    }
});

export const deleteTemplate = createAsyncThunk("templates/deleteTemplate", async ({ storeId, templateId }: any) => {
    try {
        const response = apiDeleteTemplate(storeId, templateId);
        response.then((r: any) => {
            toast(`Plantilla "${r.name}" eliminada`, {
                position: "top-right",
                hideProgressBar: false,
                className: 'bg-success text-white',
                progress: undefined,
                toastId: ""
            })

            return r
        })
        return response;
    } catch (error) {
        return error;
    }
});

export const createTemplate = createAsyncThunk("templates/createTemplate", async ({ storeId, params, file }: any) => {
    try {
        const response = apiPostTemplate(storeId, params);
        return new Promise(async (resolve, reject) => {
            const data = await response;
            if (file) {
                apiPostTemplateFile(storeId, data.id, file)
                    .then((data: any) => {
                        toast("Plantilla creada con éxito!", { position: "top-right", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' })

                        resolve(data)
                    })
                    .catch((e: any) => reject(e))
            } else {
                toast("Plantilla creada con éxito!", { position: "top-right", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' })
                resolve(data)
            }
        })
    } catch (error) {
        return error;
    }
});


export const attachFileTemplate = createAsyncThunk("templates/attachTemplate", async ({ storeId, templateId, file }: any) => {
    try {
        const response = apiPostTemplateFile(storeId, templateId, file);

        return response;
    } catch (error) {
        return error;
    }
});


export const sendMessageTest = createAsyncThunk("templates/messageTest", async ({ storeId, templateId, to, countryId }: any, { rejectWithValue }) => {
    try {
        const response = apiPostTemplateTest(storeId, templateId, {
            to,
            country_id: countryId,
        });

        response.then((data: any) => {
            if (data.response && data.response.status == 400) {
                const message = data.response.data.detail.code == 'limit_reached' ? 'Alcanzaste el límite de 10 envíos semanales, intente luego.' : 'Error, intente luego'
                toast(message, {
                    position: "top-right",
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    progress: undefined,
                })
                rejectWithValue(data.response.data)
            } else {
                toast('Mensaje de prueba enviado correctamente!', {
                    position: "top-right",
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    progress: undefined,
                })
            }
            return data
        })

        return response;
    } catch (error) {
        return error;
    }
});