import React, { useEffect, useState, useRef } from 'react'
import {
  Container,
  Button,
  UncontrolledTooltip,
  Input,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import classnames from 'classnames'
import EmojiPicker from 'emoji-picker-react'

// Import Icons
import FeatherIcon from 'feather-icons-react'
import PersonalInfo from './PersonalInfo'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import {
  getConversations,
  getConversationMessages,
  sendMessage,
} from '../../slices/chat/thunk'

// Selectors
import { selectChat, selectProfile } from 'selectors'
import Spinners from 'Components/Common/Spinner'
const avatar2 =
  'https://hips.hearstapps.com/hmg-prod/images/index-avatar-1665421955.jpg?crop=1.00xw:1.00xh;0,0&resize=1200:*'

const Chat = () => {
  const userChatShow: any = useRef()

  //Init websocket
  const socket = useRef<WebSocket | null>(null)

  const [customActiveTab, setcustomActiveTab] = useState('1')
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const dispatch = useDispatch<any>()
  const { currentStoreId } = useSelector(selectProfile)
  const [isInfoDetails, setIsInfoDetails] = useState<boolean>(false)
  const [Chat_Box_Username, setChat_Box_Username] = useState<any>('')
  const [user_Status, setUser_Status] = useState<string | null>('online')
  const [Chat_Box_Image, setChat_Box_Image] = useState<any>('')
  const [currentConversationId, setCurrentConversationId] = useState<any>(null)
  const [curMessage, setcurMessage] = useState<string>('')
  const [reply, setreply] = useState<any>('')
  const [emojiPicker, setemojiPicker] = useState<boolean>(false)

  const converIdRef = useRef(null)

  // Selector for chat state
  const { conversations, messages, loading } = useSelector(selectChat)

  // Use Effect to fetch conversations
  useEffect(() => {
    if (currentStoreId) {
      dispatch(
        getConversations({
          storeId: currentStoreId,
          status: 'OPEN',
          page: 1,
          perPage: 10,
        })
      )
      try {
        socket.current = new WebSocket(
          `wss://apiv2.whatacart.ai/ws/${currentStoreId}`
        )

        socket.current.onopen = () => {
          console.log('SOCKET CONNECTED')
        }

        socket.current.onmessage = (event) => {
          let newMessage = JSON.parse(event.data)

          if (newMessage.action == 'force_refresh') {
            dispatch(
              getConversations({
                storeId: currentStoreId,
                status: 'OPEN',
                page: 1,
                perPage: 10,
              })
            )

            if (converIdRef.current !== null) {
              dispatch(
                getConversationMessages({
                  storeId: currentStoreId,
                  conversationId: converIdRef.current,
                  page: 1,
                  perPage: 10,
                })
              )
            }
          }

          console.log('MESSAGE DATA: ', newMessage)
          console.log('CURRENT STORE: ', currentStoreId)
          console.log('CURRENT CONVER: ', converIdRef.current)
        }

        socket.current.onclose = () => {
          console.log('SOCKET CLOSED')
        }

        return () => {
          socket.current?.close()
        }
      } catch (error: any) {
        console.log('ERROR ON CONNECT: ', error)
      }
    }
  }, [dispatch, currentStoreId])

  // Use Effect to fetch messages for the selected conversation
  useEffect(() => {
    if (currentConversationId && currentStoreId) {
      converIdRef.current = currentConversationId
      dispatch(
        getConversationMessages({
          storeId: currentStoreId,
          conversationId: currentConversationId,
          page: 1,
          perPage: 10,
        })
      )
    }
  }, [dispatch, currentConversationId, currentStoreId])

  // Toggle Info details offcanvas
  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails)
  }

  // Use For Chat Box
  const userChatOpen = (conversation: any) => {
    setChat_Box_Username(conversation.phone_number)
    setCurrentConversationId(conversation.id)
    setChat_Box_Image(avatar2)
    setUser_Status(conversation.status)
    if (window.innerWidth < 892) {
      userChatShow.current.classList.add('user-chat-show')
    }
  }

  const backToUserChat = () => {
    userChatShow.current.classList.remove('user-chat-show')
  }

  useEffect(() => {
    if (userChatShow.current) {
      const scrollElement = userChatShow.current.contentWrapperEl
      scrollElement.scrollTop = scrollElement.scrollHeight
    }
  }, [messages])

  // Add message
  const addMessage = () => {
    if (curMessage !== '' && currentConversationId) {
      const message = {
        content: curMessage,
        message_type: 'text',
      }
      dispatch(
        sendMessage({
          storeId: currentStoreId,
          conversationId: currentConversationId,
          payload: message,
        })
      )
      setcurMessage('')
      setreply('')
    }
  }

  // Handle Enter key to send message
  const onKeyPress = (e: any) => {
    const { key } = e
    if (key === 'Enter') {
      e.preventDefault()
      addMessage()
    }
  }

  // Copy Message
  const handleClick = (ele: HTMLElement) => {
    const copy = ele
      .closest('.chat-list')
      ?.querySelector('.ctext-content')?.innerHTML
    if (copy) {
      navigator.clipboard.writeText(copy)
    }

    const copyClipboardElement = document.getElementById('copyClipBoard')
    if (copyClipboardElement) {
      copyClipboardElement.style.display = 'block'
      setTimeout(() => {
        copyClipboardElement.style.display = 'none'
      }, 1000)
    }
  }

  // Emoji
  const onEmojiClick = (event: any, emojiObject: any) => {
    setcurMessage(curMessage + emojiObject.emoji)
  }

  document.title = 'Chat | Burbuxa'

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div className="chat-leftsidebar">
              <div className="px-4 pt-4 mb-3">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h5 className="mb-4">Chats</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <UncontrolledTooltip placement="bottom" target="addcontact">
                      Add Contact
                    </UncontrolledTooltip>

                    <Button
                      color=""
                      id="addcontact"
                      className="btn btn-soft-success btn-sm shadow-none"
                    >
                      <i className="ri-add-line align-bottom"></i>
                    </Button>
                  </div>
                </div>
                <div className="search-box">
                  <input
                    type="text"
                    className="form-control bg-light border-light"
                    placeholder="Search here..."
                  />
                  <i className="ri-search-2-line search-icon"></i>
                </div>
              </div>

              <Nav
                tabs
                className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                      active: customActiveTab === '1',
                    })}
                    onClick={() => {
                      toggleCustom('1')
                    }}
                  >
                    Chats
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={customActiveTab} className="text-muted">
                <TabPane tabId="1" id="chats">
                  {loading ? (
                    <Spinners setLoading={() => {}} />
                  ) : (
                    <SimpleBar
                      className="chat-room-list pt-3"
                      style={{ margin: '-16px 0px 0px' }}
                    >
                      <div className="d-flex align-items-center px-4 mb-2">
                        <div className="flex-grow-1">
                          <h4 className="mb-0 fs-11 text-muted text-uppercase">
                            Direct Messages
                          </h4>
                        </div>
                      </div>

                      <div className="chat-message-list">
                        <ul
                          className="list-unstyled chat-list chat-user-list users-list"
                          id="userList"
                        >
                          {(conversations || []).map((conversation: any) => (
                            <li
                              key={conversation.id}
                              className={
                                Chat_Box_Username === conversation.phone_number
                                  ? 'active'
                                  : ''
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => userChatOpen(conversation)}
                                className={
                                  conversation.unread_count
                                    ? 'unread-msg-user'
                                    : ''
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <div
                                    className={`flex-shrink-0 chat-user-img ${
                                      conversation.status === 'OPEN'
                                        ? 'online'
                                        : 'away'
                                    } align-self-center me-2 ms-0`}
                                  >
                                    <div className="avatar-xxs">
                                      <div
                                        className={
                                          'avatar-title rounded-circle bg-light userprofile'
                                        }
                                      >
                                        {conversation.phone_number.charAt(0)}
                                      </div>
                                    </div>
                                    <span className="user-status"></span>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-truncate mb-0">
                                      {conversation.phone_number}
                                    </p>
                                  </div>
                                  {conversation.unread_count && (
                                    <div
                                      className="flex-shrink-0"
                                      id={'unread-msg-user' + conversation.id}
                                    >
                                      <span className="badge bg-dark-subtle text-body rounded p-1">
                                        {conversation.unread_count}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </SimpleBar>
                  )}
                </TabPane>
              </TabContent>
            </div>

            <div className="user-chat w-100 overflow-hidden" ref={userChatShow}>
              <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                  <div className="position-relative">
                    <div className="p-3 user-chat-topbar">
                      <Row className="align-items-center">
                        <Col sm={4} xs={8}>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 d-block d-lg-none me-3">
                              <Link
                                to="#"
                                onClick={backToUserChat}
                                className="user-chat-remove fs-18 p-1"
                              >
                                <i className="ri-arrow-left-s-line align-bottom"></i>
                              </Link>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                  {Chat_Box_Image === undefined ? (
                                    <img
                                      src={avatar2}
                                      className="rounded-circle avatar-xs"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={Chat_Box_Image}
                                      className="rounded-circle avatar-xs"
                                      alt=""
                                    />
                                  )}
                                  <span className="user-status"></span>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <h5 className="text-truncate mb-0 fs-16">
                                    <a
                                      className="text-reset username"
                                      data-bs-toggle="offcanvas"
                                      href="#userProfileCanvasExample"
                                      aria-controls="userProfileCanvasExample"
                                    >
                                      {Chat_Box_Username}
                                    </a>
                                  </h5>
                                  <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                    <small>
                                      {user_Status === null
                                        ? '24 Members'
                                        : user_Status}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="position-relative" id="users-chat">
                      <div
                        className="chat-conversation p-3 p-lg-4 simplebar-scrollable-y"
                        id="chat-conversation"
                      >
                        {loading ? (
                          <Spinners setLoading={() => {}} />
                        ) : (
                          <SimpleBar
                            ref={userChatShow}
                            style={{ height: '100%' }}
                          >
                            <ul
                              className="list-unstyled chat-conversation-list"
                              id="users-conversation"
                            >
                              {(messages || []).map((message: any) => (
                                <li
                                  className={
                                    message.direction === 'outgoing'
                                      ? 'chat-list right'
                                      : 'chat-list left'
                                  }
                                  key={message.id}
                                >
                                  <div className="conversation-list">
                                    {message.direction === 'incoming' && (
                                      <div className="chat-avatar">
                                        {Chat_Box_Image === undefined ? (
                                          <img src={avatar2} alt="" />
                                        ) : (
                                          <img src={Chat_Box_Image} alt="" />
                                        )}
                                      </div>
                                    )}
                                    <div className="user-chat-content">
                                      <div className="ctext-wrap">
                                        {message.isImages ? (
                                          <div className="message-img mb-0">
                                            {message.has_images.map(
                                              (img: {
                                                id: number
                                                image: string
                                              }) => (
                                                <div
                                                  className="message-img-list"
                                                  key={img.id}
                                                >
                                                  <div>
                                                    <a
                                                      className="popup-img d-inline-block"
                                                      href={img.image}
                                                    >
                                                      <img
                                                        src={img.image}
                                                        alt=""
                                                        className="rounded border"
                                                      />
                                                    </a>
                                                  </div>
                                                  <div className="message-img-link">
                                                    <ul className="list-inline mb-0">
                                                      <UncontrolledDropdown
                                                        tag="li"
                                                        className="list-inline-item"
                                                      >
                                                        <DropdownToggle
                                                          href="#"
                                                          tag="a"
                                                        >
                                                          <i className="ri-more-fill"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                          <DropdownItem
                                                            href="#"
                                                            className="reply-message"
                                                            onClick={() =>
                                                              setreply({
                                                                sender:
                                                                  message.sender,
                                                                msg: message.content,
                                                                id: message.id,
                                                              })
                                                            }
                                                          >
                                                            <i className="ri-reply-line me-2 text-muted align-bottom"></i>
                                                            Reply
                                                          </DropdownItem>
                                                          <DropdownItem href="#">
                                                            <i className="ri-share-line me-2 text-muted align-bottom"></i>
                                                            Forward
                                                          </DropdownItem>
                                                          <DropdownItem
                                                            href="#"
                                                            onClick={(e: any) =>
                                                              handleClick(
                                                                e.target
                                                              )
                                                            }
                                                          >
                                                            <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                                                            Copy
                                                          </DropdownItem>
                                                          <DropdownItem href="#">
                                                            <i className="ri-bookmark-line me-2 text-muted align-bottom"></i>
                                                            Bookmark
                                                          </DropdownItem>
                                                        </DropdownMenu>
                                                      </UncontrolledDropdown>
                                                    </ul>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          <>
                                            <div className="ctext-wrap-content">
                                              {message.reply ? (
                                                <>
                                                  <div className="ctext-wrap-content">
                                                    <div className="replymessage-block mb-0 d-flex align-items-start">
                                                      <div className="flex-grow-1">
                                                        <h5 className="conversation-name">
                                                          {message.reply.sender}
                                                        </h5>
                                                        <p className="mb-0">
                                                          {message.reply.msg}
                                                        </p>
                                                      </div>
                                                      <div className="flex-shrink-0">
                                                        <button
                                                          type="button"
                                                          className="btn btn-sm btn-link mt-n2 me-n3 font-size-18"
                                                        ></button>
                                                      </div>
                                                    </div>
                                                    <p className="mb-0 ctext-content mt-1">
                                                      {message.content}
                                                    </p>
                                                  </div>
                                                </>
                                              ) : (
                                                <p className="mb-0 ctext-content">
                                                  {message.content}
                                                </p>
                                              )}
                                            </div>
                                            <UncontrolledDropdown className="align-self-start message-box-drop">
                                              <DropdownToggle
                                                href="#"
                                                className="btn nav-btn"
                                                tag="a"
                                              >
                                                <i className="ri-more-2-fill"></i>
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem
                                                  href="#"
                                                  className="reply-message"
                                                  onClick={() =>
                                                    setreply({
                                                      sender: message.sender,
                                                      msg: message.content,
                                                      id: message.id,
                                                    })
                                                  }
                                                >
                                                  <i className="ri-reply-line me-2 text-muted align-bottom"></i>
                                                  Reply
                                                </DropdownItem>
                                                <DropdownItem href="#">
                                                  <i className="ri-share-line me-2 text-muted align-bottom"></i>
                                                  Forward
                                                </DropdownItem>
                                                <DropdownItem
                                                  href="#"
                                                  onClick={(e: any) =>
                                                    handleClick(e.target)
                                                  }
                                                >
                                                  <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                                                  Copy
                                                </DropdownItem>
                                                <DropdownItem href="#">
                                                  <i className="ri-bookmark-line me-2 text-muted align-bottom"></i>
                                                  Bookmark
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </>
                                        )}
                                      </div>
                                      <div className="conversation-name">
                                        <small className="text-muted time">
                                          {message.sent_at || ''}
                                        </small>
                                        <span className="text-success check-message-icon">
                                          <i className="ri-check-double-line align-bottom"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </SimpleBar>
                        )}
                      </div>
                      {/* copy msg */}
                      <Alert
                        color="warning"
                        className="copyclipboard-alert px-4 fade"
                        id="copyClipBoard"
                        role="alert"
                      >
                        Message copied
                      </Alert>
                      {/* emoji picker */}
                      {emojiPicker && (
                        <EmojiPicker
                          onEmojiClick={onEmojiClick}
                          width={250}
                          height={382}
                        />
                      )}
                    </div>

                    <div className="chat-input-section p-3 p-lg-4">
                      <form id="chatinput-form">
                        <Row className="g-0 align-items-center">
                          <div className="col-auto">
                            <div className="chat-input-links me-2">
                              <div className="links-list-item">
                                <button
                                  type="button"
                                  className="btn btn-link text-decoration-none emoji-btn"
                                  id="emoji-btn"
                                  onClick={() => setemojiPicker(!emojiPicker)}
                                >
                                  <i className="bx bx-smile align-middle"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="chat-input-feedback">
                              Please Enter a Message
                            </div>
                            <input
                              type="text"
                              value={curMessage}
                              onKeyDown={onKeyPress}
                              onChange={(e) => setcurMessage(e.target.value)}
                              className="form-control chat-input bg-light border-light"
                              id="chat-input"
                              placeholder="Type your message..."
                            />
                          </div>
                          <div className="col-auto">
                            <div className="chat-input-links ms-2">
                              <div className="links-list-item">
                                <button
                                  type="button"
                                  disabled={curMessage === ''}
                                  onClick={() => {
                                    addMessage()
                                    setemojiPicker(false)
                                  }}
                                  className="btn btn-success chat-send waves-effect waves-light disable"
                                >
                                  <i className="ri-send-plane-2-fill align-bottom"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </form>
                    </div>

                    <div className={reply ? 'replyCard show' : 'replyCard'}>
                      <Card className="mb-0">
                        <CardBody className="py-3">
                          <div className="replymessage-block mb-0 d-flex align-items-start">
                            <div className="flex-grow-1">
                              <h5 className="conversation-name">
                                {reply && reply.sender}
                              </h5>
                              <p className="mb-0">{reply && reply.msg}</p>
                            </div>
                            <div className="flex-shrink-0">
                              <button
                                type="button"
                                id="close_toggle"
                                className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                                onClick={() => setreply('')}
                              >
                                <i className="bx bx-x align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <PersonalInfo
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(false)}
        currentuser={Chat_Box_Username}
        cuurentiseImg={Chat_Box_Image}
      />
    </React.Fragment>
  )
}

export default Chat
