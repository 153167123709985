import { createSlice } from "@reduxjs/toolkit";

const SignupErrorCodes: { [key: string]: string } = {
  user_already_enrolled: 'El e-mail ya se encuentra registrado',
  unkown: 'Se ha producido un error, intente luego',
}

export const initialState: any = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false
};

const registerSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    registerUserSuccessful(state, action) {
      state.user = action.payload;
      state.loading = false;
      state.success = true;
      state.registrationError = null;
    },
    registerUserFailed(state, action) {
      state.user = null;
      state.loading = false;
      state.registrationError = SignupErrorCodes[action?.payload?.data?.detail.code] || SignupErrorCodes.unkown;
      state.error = true;
    },
    resetRegisterFlagChange(state) {
      state.success = false;
      state.error = false;
    },
    apiErrorChange(state: any, action: any) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogout = false;
    }
  }
});

export const {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange
} = registerSlice.actions;

export default registerSlice.reducer;