import { createSlice } from "@reduxjs/toolkit";
import { getCountries, getPaymentMethods, getProductCategories, } from './thunk';

export const initialState: any = {
    paymentMethods: [],
    countries: [],
};

const StoreSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPaymentMethods.fulfilled, (state: any, action: any) => {
            state.paymentMethods = action.payload;
        })

        builder.addCase(getPaymentMethods.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        })

        builder.addCase(getCountries.fulfilled, (state: any, action: any) => {
            state.countries = action.payload;
        })

        builder.addCase(getCountries.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        })

        builder.addCase(getProductCategories.fulfilled, (state: any, action: any) => {
            state.productCategories = action.payload;
        })

        builder.addCase(getProductCategories.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        })

    }
});

export default StoreSlice.reducer;