import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectProfile } from 'selectors';
import { deleteCampaign, pauseCampaign, resumeCampaign } from 'slices/campaign/thunk';
import { setCurrentStore } from 'slices/thunks';
import { ICampaign } from 'types/Dashboard';

interface CampaignActionDropdownProps {
    campaign: ICampaign
}

const CampaignActionDropdown = ({ campaign }: CampaignActionDropdownProps) => {
    const navigate = useNavigate()
    const profile = useSelector(selectProfile)
    const dispatch: any = useDispatch()

    const pause = (c: ICampaign) => {
        if (profile.currentStoreId) {
            dispatch(
                pauseCampaign({
                    storeId: profile.currentStoreId,
                    campaignId: c.id
                })
            )
        }
    }

    const resume = (c: ICampaign) => {
        if (profile.currentStoreId) {
            dispatch(
                resumeCampaign({
                    storeId: profile.currentStoreId,
                    campaignId: c.id
                })
            )
        }
    }

    const _deleteCampaign = (c: ICampaign) => {
        if (profile.currentStoreId) {
            dispatch(
                deleteCampaign({
                    storeId: profile.currentStoreId,
                    campaignId: c.id
                })
            )
        }
    }

    return (
        <ButtonGroup>
            <UncontrolledDropdown>
                <DropdownToggle tag="button" className='btn btn-light' style={{
                    paddingLeft: 5,
                    paddingRight: 5,
                }}>
                    <i className="mdi mdi-cog"></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => navigate(`/campaigns/edit/${campaign.id}/`)}>
                        <span className="mdi mdi-circle-edit-outline"></span> Editar campaña
                    </DropdownItem>
                    {['INACTIVE', 'PAUSED'].indexOf(campaign.status) !== -1 && <DropdownItem onClick={() => resume(campaign)} className='text-success'><span className="mdi mdi-play-circle-outline"></span> Activar envíos</DropdownItem>}
                    {campaign.status == 'ACTIVE' && <DropdownItem onClick={() => pause(campaign)} className='text-warning'><span className="mdi mdi-stop-circle-outline"></span> Detener envíos</DropdownItem>}
                    <DropdownItem onClick={() => _deleteCampaign(campaign)} className='text-danger'><span className="mdi mdi-delete-circle-outline"></span> Eliminar campaña</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
    );
};

export default CampaignActionDropdown;
