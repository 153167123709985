import getChartColorsArray from 'Components/Common/ChartsDynamicColor';
import React, { useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Card, CardHeader } from 'reactstrap';
import { selectCampaigns, selectProfile } from 'selectors';
import { getCampaignDistribution } from 'slices/campaign/thunk';
import { Distribution } from 'types/Dashboard';


interface CampaignGroupConvertionChartProps {
    distribution?: Distribution[]
}

const CampaignGroupConvertionChart = ({ distribution = [] }: CampaignGroupConvertionChartProps) => {
    const series = distribution.map((each) => each.amount);
    const colors = getChartColorsArray('["--vz-primary", "--vz-success", "--vz-secondary", "--vz-info", "--vz-warning"]');

    var options: any = {
        labels: distribution.map((each) => each.campaign_name),
        chart: {
            height: 333,
            type: "donut",
        },
        legend: {
            position: "bottom",
        },
        stroke: {
            show: false,
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            },
        },
        colors,
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="donut"
                height="333"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

const CampaignGroupConvertionPie = ({ distribution }: any) => {

    if (!distribution || distribution.length == 0) {
        return <></>
    }

    return (
        <Card className="card-height-100">
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Distribución porcentual según facturación en US$</h4>
                <div className="flex-shrink-0">
                </div>
            </CardHeader>

            <div className="card-body">
                {/* <div dir="ltr"> */}
                <CampaignGroupConvertionChart
                    distribution={distribution}
                />
                {/* </div> */}
            </div>
        </Card>
    );
};

export default CampaignGroupConvertionPie;
