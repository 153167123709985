import React from 'react'


const TemplateStatus = ({ status }: any) => {
    switch (status) {
        case "APPROVED":
            return <span className="badge bg-success-subtle  text-success text-uppercase">Aprobado</span>;
        case "REJECTED":
            return <span className="badge bg-danger-subtle  text-danger text-uppercase">Rechazado</span>;
        case "PAUSED":
            return <span className="badge bg-warning-subtle  text-danger text-uppercase">Pausado</span>;
        case "DISABLED":
            return <span className="badge bg-danger-subtle  text-danger text-uppercase">Deshabilitado</span>;
        case "PENDING":
            return <span className="badge bg-warning-subtle text-warning  text-uppercase">Pendiente</span>;
    }
}


export default TemplateStatus 