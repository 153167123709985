import { createSlice } from "@reduxjs/toolkit";
import { getOrderList, } from './thunk';

export const initialState: any = {
    orderItems: [],
    total: 0,
    skip: 0,
    limit: 0,
    status: 'loaded' as ('loading' | 'loaded')
};

const OrderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOrderList.fulfilled, (state: any, action: any) => {
            state.orderItems = action.payload.items;
            state.total = action.payload.total;
            state.skip = action.payload.skip;
            state.limit = action.payload.limit;
            state.status = 'loaded'
        })
        builder.addCase(getOrderList.pending, (state: any, action: any) => {
            state.status = 'loading'
            state.orderItems = []
            state.total = 0
            state.skip = 0
        })
        builder.addCase(getOrderList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.status = 'loaded'
        })
    }
});

export default OrderSlice.reducer;