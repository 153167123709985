import { LoginData, PersistentLoginData } from "types/Auth";

interface Storage {
    setItem: (key: string, data: string) => void;
    getItem: (key: string) => string | null;
    removeItem: (key: string) => void;
}

class LocalStorage implements Storage {
    setItem = (key: string, data: string) => {
        sessionStorage.setItem(key, data);
    }

    getItem = (key: string) => {
        return sessionStorage.getItem(key);
    }

    removeItem = (key: string) => {
        sessionStorage.removeItem(key);
    }
}

class AuthenticationService {
    authKey = 'authUser';
    _accessToken: string | null = null;
    _refreshToken: string | null = null;
    _storage: Storage | null;

    constructor(storage: Storage) {
        this._storage = storage;

        const data = storage.getItem(this.authKey);
        if (data) {
            const pData = JSON.parse(data);
            this._accessToken = pData['accessToken'];
        }
    }

    onLogin = (authData: LoginData) => {
        const expiresAt = new Date().getTime() + authData.expiresIn * 1000;

        const pData: PersistentLoginData = {
            accessToken: authData.accessToken,
            expiresAt: expiresAt,
        };
        this._refreshToken = authData.refreshToken;
        this._accessToken = authData.accessToken;
        this._storage?.setItem(this.authKey, JSON.stringify(pData));
    }

    getRefreshToken = () => {
        return this._refreshToken;
    }

    getAccessToken = () => {
        return this._accessToken;
    }

    isExpiredAuth = () => {
        let expired;
        const session = this._storage?.getItem(this.authKey);

        if (session) {
            const pData: PersistentLoginData = JSON.parse(session);
            expired = pData.expiresAt > new Date().getTime();
        } else {
            expired = true;
        }
        return expired;
    }

    isLoggedIn = (): boolean => {
        return !!this._storage?.getItem(this.authKey);
    }

    logout = () => {
        this._accessToken = null;
        this._storage?.removeItem(this.authKey);
    }
}

export default new AuthenticationService(new LocalStorage());