import { Link } from "react-router-dom"
import { format } from "date-fns"
import TemplateStatus from "./TemplateStatus"
import TemplateActionDropdown from "./TemplateActionDropdown"


const TemplateTable = ({ items, loading }: any) => {
    return <div className="table-responsive">
        <table className="table align-middle position-relative table-nowrap">
            <thead className="table-active">
                <tr>
                    <th scope="col" className="text-center">Nombre de la plantilla</th>
                    <th scope="col" className="text-center">Fecha de creación</th>
                    <th scope="col" className="text-center">Estado</th>
                    <th scope="col" className="text-center">Cuerpo del mensaje</th>
                    <th scope="col" className="text-center">Acciones</th>
                </tr>
            </thead>

            <tbody id="campaign-list">
                {(loading || items == null) && Array(4).fill(0).map((key, index) => <tr key={index}>
                    {Array(5).fill(0).map((_, sIndex) => <td key={sIndex}>
                        <p className="card-text placeholder-glow">
                            <span className="placeholder col-12"></span>
                        </p>
                    </td>)}
                </tr>)}
                {(items || []).map((item: any, key: any) => (<tr key={key}>
                    <td>
                        <div className="text-center text-primary">
                            {item.name}
                        </div>
                    </td>
                    <td>
                        <div className="text-dark text-center">
                            {format(new Date(item.created_at), 'dd/MM/yyyy')}
                        </div>
                    </td>
                    <td className="text-center">
                        <TemplateStatus status={item.status} />
                    </td>
                    <td>
                        <div className="d-flex align-items-start fs-10 text-dark" style={{
                            whiteSpace: 'pre-wrap',
                            wordWrap: "break-word",
                        }}>
                            {item.text}
                        </div>
                    </td>
                    <td className="text-center">
                        <TemplateActionDropdown template={item} />
                    </td>
                </tr>))}
            </tbody>
        </table>
    </div >
}

export default TemplateTable 
