import React from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

type DisplayDataCardProps = {
    label: string
    prefix?: string
    suffix?: string
    value: number
    link?: string
    bgIconColor?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
    icon?: string
}

const DisplayDataCard = (props: DisplayDataCardProps) => {

    return (
        <Card className="card-animate">
            <CardBody>
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{props.label}</p>
                    </div>
                    <div className="flex-shrink-0">
                    </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                        <h4 className="fs-20 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target={props.value}>
                            <CountUp
                                start={0}
                                prefix={props.prefix}
                                suffix={props.suffix}
                                end={props.value}
                                duration={1.5}
                                decimals={Number.isInteger(props.value) ? 0 : 2} // Dynamic decimal places
                            />
                        </span></h4>
                        {props.link && <Link to="#" className="text-decoration-underline">{props.link}</Link>}
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                        {props.icon && <span className={"avatar-title rounded fs-3 bg-" + props.bgIconColor + "-subtle"}>
                            <i className={`text-${props.bgIconColor} ${props.icon}`}></i>
                        </span>}
                    </div>
                </div>
            </CardBody>
        </Card>
    )
};

export default DisplayDataCard;