import { createSlice } from "@reduxjs/toolkit";
import { createTemplate, deleteTemplate, getTemplates, sendMessageTest, } from './thunk';
import { deleteCampaign } from "slices/campaign/thunk";

export const initialState: any = {
    templateItems: [],
    total: 0,
    skip: 0,
    limit: 0,
    redirToList: false,
    status: 'loaded' as ('loading' | 'loaded'),
    messageTestStatus: 'idle' as ('idle' | 'sending' | 'sent' | 'error'),
    messageTestError: '',
};

const TemplateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTemplates.pending, (state: any, action: any) => {
            state.status = 'loading'
        })

        builder.addCase(getTemplates.fulfilled, (state: any, action: any) => {
            state.templateItems = action.payload.items
            state.total = action.payload.total
            state.limit = action.payload.limit
            state.skip = action.payload.skip
            state.status = 'loaded'
            state.redirToList = false
        })

        builder.addCase(getTemplates.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.status = 'loaded'
        })


        builder.addCase(deleteTemplate.pending, (state: any, action: any) => {
            state.status = 'loading'
        })

        builder.addCase(deleteTemplate.fulfilled, (state: any, action: any) => {
            const { status, id } = action.payload
            state.status = 'loaded'

            const index = state.templateItems.findIndex((template: any) => template.id == id)
            state.templateItems.splice(index, 1)
        })

        builder.addCase(createTemplate.pending, (state: any, action: any) => {
            state.status = 'loading'
        })

        builder.addCase(createTemplate.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
            state.redirToList = true
        })

        builder.addCase(sendMessageTest.pending, (state: any, action: any) => {
            state.messageTestStatus = 'sending'
        })

        builder.addCase(sendMessageTest.fulfilled, (state: any, action: any) => {
            state.messageTestStatus = 'sent'
        })

        builder.addCase(sendMessageTest.rejected, (state: any, action: any) => {
            state.messageTestStatus = 'error'
        })
    }
});

export default TemplateSlice.reducer;