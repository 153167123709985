import CampaignForm from 'Components/Campaigns/CampaignForm'
import CampaignModal from 'Components/Campaigns/CampaignModal'
import BreadCrumb from 'Components/Common/BreadCrumb'
import UiContent from 'Components/Common/UiContent'
import ReviewConfigForm from 'Components/Reviews/ReviewConfigForm'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { selectCampaigns, selectProfile, selectReviews } from 'selectors'
import { createCampaignBudget, getCampaignById, getCampaigns, updateCampaign } from 'slices/campaign/thunk'
import { getReviewConfig, saveReviewConfig } from 'slices/reviews/thunk'
import { getTemplates } from 'slices/templates/thunk'


const ReviewConfig = () => {
    const { id } = useParams()
    const [values, setValues] = useState<any>({});
    const navigate = useNavigate()
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const reviews = useSelector(selectReviews)

    const [showCampaignModal, setShowCampaignModal] = useState(false)

    const toggleCampaignModal = () => setShowCampaignModal(!showCampaignModal)

    useEffect(() => {
        if (currentStoreId) {
            dispatch(
                getReviewConfig({
                    storeId: currentStoreId,
                })
            )
        }
    }, [currentStoreId, id])

    document.title = `Configurar Reseñas | Burbuxa`

    const onSubmit = (values: any) => {
        dispatch(
            saveReviewConfig({
                storeId: currentStoreId,
                config: values
            })
        )
    }

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Configuración de Reseña" pageTitle="Reseñas" />
                <ReviewConfigForm
                    config={reviews.config}
                    onSubmit={onSubmit}
                />
            </Container>
        </div>
    </React.Fragment>
}

export default ReviewConfig 