import CampaignAbandonedMetrics from "Components/Campaigns/CampaignAbandonedMetrics";
import CampaignConvertionMetrics from "Components/Campaigns/CampaignConvertionMetrics";
import CampaignFilter from "Components/Campaigns/CampaignFilter";
import CampaignGroupConvertionPie from "Components/Campaigns/CampaignGroupConvertionPie";
import CampaignGroupReportLoader from "Components/Campaigns/CampaignGroupReportLoader";
import CampaignTransactionalMetrics from "Components/Campaigns/CampaignTransactionalMetrics";
import StoreMetrics from "Components/Campaigns/StoreMetrics";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { selectCampaigns, selectOrder, selectProfile } from "selectors";
import { getReport, getStoreReport } from "slices/campaign/thunk";
import { getOrderList } from "slices/order/thunk";
import { OrderStatus } from "types/Dashboard";


const CampaignDashboard = () => {
  const dispatch: any = useDispatch();

  const { campaignReport, status } = useSelector(selectCampaigns)
  const { currentStoreId } = useSelector(selectProfile)
  const { orderItems } = useSelector(selectOrder)
  const [params, setParams] = useState<any>({
    storeId: currentStoreId,
    campaign: null,
    fromDate: null,
    toDate: null,
    group: null,
  })

  document.title = "Dashboard | Burbuxa";

  useEffect(() => {
    if (currentStoreId) {
      setParams({
        campaign: null,
        fromDate: null,
        toDate: null,
        group: null,
        storeId: currentStoreId,
      })

      dispatch(
        getOrderList({
          storeId: currentStoreId,
          params: {
            status: OrderStatus.CONVERTED
          }
        })
      )
    }
  }, [currentStoreId])

  useEffect(() => {
    if (params.storeId) {
      if (!params.campaign && !params.group) {
        dispatch(
          getStoreReport({
            storeId: params.storeId,
            from_date: params.fromDate,
            to_date: params.toDate,
          })
        )
      } else if (params.campaign) {
        dispatch(
          getReport({
            storeId: params.storeId,
            campaignId: params.campaign.id,
            from_date: params.fromDate,
            to_date: params.toDate,
          })
        )
      } else if (params.group) {
        dispatch(
          getReport({
            storeId: params.storeId,
            groupId: params.group.id,
            from_date: params.fromDate,
            to_date: params.toDate,
          })
        )
      }
    }
  }, [params.storeId, params.campaign, params.fromDate, params.toDate, params.group])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <CampaignFilter
                  values={params}
                  onChange={(values: any) => setParams({
                    ...params,
                    ...values
                  })}
                />
              </div>
            </Col>
          </Row>
          {params.group && status == 'loading' &&
            <CampaignGroupReportLoader />
          }
          <Row>
            {campaignReport?.distribution && <Col xxl={12} className="text-center">
              <CampaignGroupConvertionPie distribution={campaignReport.distribution} />
            </Col>}
            <Col xxl={12}>
              {(!params.campaign && !params.group) && <StoreMetrics report={campaignReport} orderItems={orderItems} />}
              {(params.campaign && params.campaign.campaign_type == 'CONVERSION' && params.campaign.rule == 'IF_ABANDONED') &&
                <CampaignAbandonedMetrics report={campaignReport} />}
              {(params.campaign && params.campaign.campaign_type == 'CONVERSION' && params.campaign.rule != 'IF_ABANDONED') &&
                <CampaignConvertionMetrics report={campaignReport} />}
              {params.campaign && params.campaign.campaign_type == 'TRANSACTIONAL' && <CampaignTransactionalMetrics report={campaignReport} />}

              {params.group && campaignReport && campaignReport.groupType == 'ABANDONED' &&
                <CampaignAbandonedMetrics report={campaignReport} />}
              {params.group && campaignReport && campaignReport.groupType == 'CONVERSION' &&
                <CampaignConvertionMetrics report={campaignReport} />}
              {params.group && campaignReport && campaignReport.groupType == 'TRANSACTIONAL' &&
                <CampaignTransactionalMetrics report={campaignReport} />}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CampaignDashboard;
