import { createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    apiDeleteGroup,
    apiGetGroups,
    apiPostGroup,
} from "../../helpers/backend_helper";

export const getGroups = createAsyncThunk("groups/getGroups", async ({ storeId }: any) => {
    try {
        const response = apiGetGroups(storeId);
        return response;
    } catch (error) {
        return error;
    }
});

export const createGroup = createAsyncThunk("groups/createGroup", async ({ storeId, params }: any) => {
    try {
        const response = apiPostGroup(storeId, params)

        const data = await response

        toast("¡Conjunto de campañas creado con éxito!", { position: "top-right", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' })

        return response
    } catch (error) {
        return error
    }
});

export const deleteGroup = createAsyncThunk("groups/deleteGroup", async ({ storeId, groupId }: any) => {
    try {
        const response = apiDeleteGroup(storeId, groupId);
        const data = await response

        toast("¡Conjunto de campañas eliminado con éxito!", { position: "top-right", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' })
        return response;
    } catch (error) {
        return error;
    }
});


