import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    apiGetCountries,
    apiGetPaymentMethods,
    apiGetCampaignProductCategories
} from "../../helpers/backend_helper";

export const getPaymentMethods = createAsyncThunk("stores/getPaymentMethods", async ({ storeId }: any) => {
    try {
        const response = apiGetPaymentMethods(storeId);
        return response;
    } catch (error) {
        return error;
    }
});

export const getCountries = createAsyncThunk("stores/countries", async () => {
    try {
        const response = apiGetCountries();
        return response;
    } catch (error) {
        return error;
    }
});

export const getProductCategories = createAsyncThunk("store/getCampaignProductCategories", async ({
    storeId
}: any) => {
    try {
        const response = apiGetCampaignProductCategories(storeId);
        return response;
    } catch (error) {
        return error
    }
})