import DisplayDataCard from 'Components/Common/DisplayDataCard'
import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import CampaignConvertionPie from './CampaignConvertionPie'


const CampaignAbandonedMetrics = ({ report }: any) => {

    return <React.Fragment>
        <Container fluid>
            <Row>
                <Col>
                    <div className="h-100">
                        <Row>
                            <Col sm={6} md={3}>
                                <DisplayDataCard
                                    value={report?.totalRecoveredVolume}
                                    label='Facturación'
                                    bgIconColor="secondary"
                                    icon="bx bx-dollar-circle"
                                    prefix="USD "
                                />
                            </Col>

                            <Col sm={6} md={3}>
                                <DisplayDataCard
                                    value={report?.roi}
                                    label='Retorno de inversión'
                                    suffix=" %"
                                    bgIconColor="primary"
                                    icon="bx bx-repost"
                                />
                            </Col>

                            <Col sm={6} md={3}>
                                <DisplayDataCard
                                    value={report?.totalConversionRate}
                                    label='Tasa de conversión'
                                    suffix=" %"
                                    bgIconColor="success"
                                    icon="bx bx-filter"
                                />
                            </Col>

                            <Col sm={6} md={3}>
                                <DisplayDataCard
                                    value={report?.totalClientsReached}
                                    label='Clientes únicos contactados'
                                    bgIconColor="info"
                                    icon="bx bx-user-circle" />
                            </Col>
                            <Col sm={6} md={4}>
                                <DisplayDataCard
                                    value={report?.totalMessagesSent}
                                    label='Mensajes enviados'
                                    bgIconColor="danger"
                                    icon="bx bx-message-dots"
                                />
                            </Col>
                            <Col sm={6} md={4}>
                                <DisplayDataCard
                                    value={report?.totalInvestment}
                                    label='Inversión'
                                    prefix='USD '
                                    bgIconColor="warning"
                                    icon="bx bx-check-circle"
                                />
                            </Col>

                            <Col sm={6} md={4}>
                                <DisplayDataCard
                                    value={report?.totalRecoveredCarts}
                                    label='Carritos recuperados'
                                    bgIconColor="info"
                                    icon="bx bxs-cart-add" />
                            </Col>

                            {report && report.campaign && <Col sm={6} md={4}>
                                <CampaignConvertionPie
                                    campaignId={report.campaign.id}
                                />
                            </Col>}
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    </React.Fragment>
}

export default CampaignAbandonedMetrics
