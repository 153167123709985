import CampaignForm from 'Components/Campaigns/CampaignForm'
import CampaignModal from 'Components/Campaigns/CampaignModal'
import BreadCrumb from 'Components/Common/BreadCrumb'
import UiContent from 'Components/Common/UiContent'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { selectCampaigns, selectProfile } from 'selectors'
import { createCampaignBudget, getCampaignById, getCampaigns, updateCampaign } from 'slices/campaign/thunk'


const CampaignEdit = () => {
    const { id } = useParams()
    const [values, setValues] = useState<any>({});
    const navigate = useNavigate()
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const { campaign, budget, status, redirToList } = useSelector(selectCampaigns)
    const [showCampaignModal, setShowCampaignModal] = useState(false)

    const toggleCampaignModal = () => setShowCampaignModal(!showCampaignModal)

    useEffect(() => {
        if (currentStoreId && id) {
            dispatch(
                getCampaignById({
                    storeId: currentStoreId,
                    campaignId: id
                })
            )
        }
    }, [currentStoreId, id])

    useEffect(() => {
        if (redirToList) {
            navigate('/campaigns/')
        }
    }, [redirToList])

    document.title = `Editar campaña ${campaign?.name} | Burbuxa`

    const _cleanValues = (values: any) => {
        const nValues = { ...values }
        Object.keys(nValues).map((key) => {
            if (nValues[key] === "") {
                nValues[key] = null
            }
        })
        return nValues
    }

    const onSubmit = (values: any, file?: File, showBudget: boolean = true) => {
        const cleanedValues = _cleanValues(values)

        setValues(cleanedValues);

        if (showBudget) {
            setShowCampaignModal(true)
            dispatch(
                createCampaignBudget({
                    storeId: currentStoreId,
                    payload: cleanedValues,
                })
            )
        } else {
            onSave(undefined, cleanedValues)
        }
    }

    const onSave = (status: any, pValues?: any) => {
        let file;
        const nValues = {
            ...(pValues ?? _cleanValues(values)),
            status,
        }

        if (nValues.file) {
            file = nValues.file
            delete nValues['file']
        } else {
            file = null
        }

        dispatch(
            updateCampaign({
                storeId: currentStoreId,
                campaignId: campaign.id,
                payload: nValues,
                file,
            })
        )
    }

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Editar campaña" pageTitle="Campañas" />
                <CampaignForm
                    campaign={campaign}
                    onSubmit={(values: any, f?: File) => {
                        onSubmit(values, f, values.rule !== 'IF_ABANDONED')
                    }}
                />
            </Container>
        </div>
        {status === 'loaded' && budget !== null && <CampaignModal
            toggle={toggleCampaignModal}
            isOpen={showCampaignModal}
            budget={budget}
            onActivate={values.scheduled_at ? null : () => onSave('ACTIVE')}
            onSave={() => onSave('INACTIVE')}
            title="Presupuesto de campaña"
            params={values}
        />}
    </React.Fragment>
}

export default CampaignEdit