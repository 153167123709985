import React from "react";
import { Modal, ModalBody, ModalHeader, Form, Row, Col, Label, Input, FormFeedback, Spinner } from "reactstrap";
import { useFormik } from 'formik';
import { useSelector } from 'react-redux'
import { selectGroup } from "selectors";


const GroupModal = ({ show, onSubmit, toggle }: any) => {
    const groups = useSelector(selectGroup)
    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
        },
        onSubmit(values, formikHelpers) {
            onSubmit(values)
        },
    })

    return (
        <Modal isOpen={show} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>Crear conjunto de campañas</ModalHeader>
            <ModalBody className="">
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit()
                }}>

                    <Row>
                        <Col sm={12} md={12}>
                            <div className="my-">
                                <Label htmlFor="name" className="form-label font-bold">Nombre del conjunto de campañas</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={formik.values.name}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    invalid={!!(formik.errors.name && formik.touched.name)}
                                    placeholder="Ejemplo: Hotsale 2023"
                                    required
                                />
                                {formik.errors.name && formik.touched.name ? (
                                    <FormFeedback type="invalid" className='d-block'>{formik.errors.name}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>

                    <div className="d-flex gap-2 justify-content-end mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={toggle}
                        >
                            Cancelar
                        </button>{' '}
                        <button type="submit" className="btn btn-success" disabled={groups.status === 'loading'}>
                            {groups.status === 'loading' && <span className="d-flex align-items-center">
                                <span className="flex-grow-1 me-2">
                                    Cargando...
                                </span>
                                <Spinner size="sm" className="flex-shrink-0" role="status"> Cargando... </Spinner>
                            </span>}{' '}
                            {groups.status !== 'loading' && 'Crear conjunto'}
                        </button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};


export default GroupModal;
