import React from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

type DisplayDataLoadingProps = {
}

const DisplayDataLoading = (props: DisplayDataLoadingProps) => {

    return <React.Fragment>
        <Card className="card-animated">
            <CardBody>
                <h5 className="card-title placeholder-glow">
                    <span className="placeholder placeholder-lg col-6"></span>
                </h5>
                <p className="card-text placeholder-glow">
                    <span className="placeholder col-7"></span>
                </p>
            </CardBody>
        </Card>
    </React.Fragment>
};

export default DisplayDataLoading;