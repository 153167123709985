import React, { useEffect, useRef, useState } from 'react';

//import Components
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, InputGroup, Button, Label, Row, Spinner } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//Import Flatepicker
import { Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { getPaymentMethods } from 'slices/stores/thunk';
import { useSelector } from 'react-redux';
import { selectCampaigns, selectProfile, selectTemplate } from 'selectors';
import { getTemplates } from 'slices/templates/thunk';
import { getCampaigns } from 'slices/campaign/thunk';
import TemplateVariableDropdown from './TemplateVariableDropdown';


const TemplateForm = ({
    onSubmit,
    campaign,
}: any) => {
    const ref = useRef<CKEditor<ClassicEditor> | null>(null);
    const fileRef = useRef<any>(null);
    const navigate = useNavigate();
    const { status } = useSelector(selectTemplate)
    const [showFile, setShowFile] = useState(false)

    const addFile = (show: boolean) => {
        setShowFile(show)
        if (!show) {
            _clearFile()
        }
    }

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            file: '',
            name: '',
            language: '' as ('ES' | 'EN'),
            text: '',
            media_type: '',
            category: '',
            buttons: [] as {
                type: 'VISIT',
                target: string,
                text: string,
            }[],
        },
        onSubmit: values => {
            onSubmit(values)
        },
    });

    const insertAbandonedUrl = (name: string, index: number) => {
        formik.setFieldValue(name, (formik.values.buttons[index].target || '') + '{{abandoned_url}}')
    }

    const insertOrderUrl = (name: string, index: number) => {
        formik.setFieldValue(name, (formik.values.buttons[index].target || '') + '{{order_status_url}}')
    }

    const insertTrackingUrl = (name: string, index: number) => {
        formik.setFieldValue(name, (formik.values.buttons[index].target || '') + '{{shipping_tracking_url}}')
    }


    const _clearFile = () => {
        fileRef.current.value = null
        formik.setFieldValue("file", null)
    }

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardBody>
                                <p className="text-muted">Desde aquí podrás crear una plantilla que es el contenido de los mensajes. Luego podrás asociarlo a una campaña para enviar.</p>
                                <div className="live-preview">
                                    <Form onSubmit={(e: any) => {
                                        e.preventDefault();
                                        formik.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="name" className="form-label font-bold">Nombre</Label>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        value={formik.values.name}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.name && formik.touched.name)}
                                                        required
                                                    />
                                                    {formik.errors.name && formik.touched.name ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="language" className="form-label font-bold">Idioma</Label>
                                                    <Input
                                                        type="select"
                                                        name="language"
                                                        className="form-control"
                                                        value={formik.values.language}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.language && formik.touched.language)}
                                                        required
                                                    >
                                                        <option value="">-- Seleccione el idioma --</option>
                                                        <option value="ES">Español</option>
                                                        <option value="EN">Inglés</option>
                                                    </Input>
                                                    {formik.errors.language && formik.touched.language ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.language}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12} md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="category" className="form-label font-bold">Categoría</Label>
                                                    <Input
                                                        type="select"
                                                        name="category"
                                                        className="form-control"
                                                        value={formik.values.category}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.category && formik.touched.category)}
                                                        required
                                                    >
                                                        <option value="">-- Seleccione la categoría --</option>
                                                        <option value="MARKETING">Marketing (más elegida)</option>
                                                        <option value="UTILITY">Utility</option>
                                                        <option value="AUTHENTICATION">Authentication</option>
                                                    </Input>
                                                    {formik.errors.category && formik.touched.category ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.category}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={8} md={8}>
                                                <div className='d-flex justify-content-between align-items-end mb-1'>
                                                    <Label className="form-label">Contenido</Label>
                                                    <TemplateVariableDropdown onSelectVariable={(variable: string) => {
                                                        if (ref.current && ref.current.editor) {
                                                            const editor = ref.current.editor
                                                            editor.model.change((writer) => {
                                                                const position = editor.model.document.selection.getFirstPosition()
                                                                writer.insertText(`{{${variable}}}`, position ? position : undefined);
                                                            });
                                                        }
                                                    }} />
                                                </div>
                                                <CKEditor
                                                    ref={ref}
                                                    config={{
                                                        removePlugins: [
                                                            'Image',
                                                            'CKFinder',
                                                            'EasyImage',
                                                            'ImageCaption',
                                                            'ImageStyle',
                                                            'ImageToolbar',
                                                            'ImageUpload',
                                                            'MediaEmbed',
                                                            'Table',
                                                            'Link',
                                                            'List',
                                                            'PasteFromOffice',
                                                            'TableToolbar',
                                                            'BlockQuote',
                                                            'HeadingEditing',
                                                            'Heading',
                                                            'Indent',
                                                        ]
                                                    }}
                                                    editor={ClassicEditor}
                                                    data=""
                                                    onChange={(event: any, editor: any) => {
                                                        const cleaned = editor.getData()
                                                            .replace(/<\/p>/g, '\n')
                                                            .replace(/<br>/g, '\n')
                                                            .replace(/<strong>/g, '*')
                                                            .replace(/<\/strong>/g, '*')
                                                            .replace(/<em>/g, '_')
                                                            .replace(/<\/em>/g, '_')
                                                            .replace(/<p>/g, '')
                                                            .replace(/&nbsp;/g, ' ')
                                                            .replace(/<i>/g, '_')
                                                            .replace(/<\/i>/g, '_')
                                                            .replace(/&amp;/g, '&')
                                                            .replace(/&lt;/g, '<')
                                                            .replace(/&gt;/g, '>')
                                                            .replace(/&quot;/g, '"')
                                                            .replace(/&#39;/g, '\'')

                                                        formik.setFieldValue("text", cleaned);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-2">
                                            <Col sm={12} md={6}>
                                                {!showFile ? (<button type="button" className="btn btn-sm btn-success" onClick={() => addFile(true)}>
                                                    <i className="mdi mdi-plus"></i> Agregar archivo/multimedia
                                                </button>) : (<button type="button" className="btn btn-sm btn-danger" onClick={() => addFile(false)}>
                                                    <i className="mdi mdi-minus"></i> Eliminar archivo/multimedia
                                                </button>)}
                                            </Col>
                                        </Row>
                                        {showFile && <>
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <div className="mb-2 mt-2">
                                                        <Label htmlFor="media_type" className="form-label">Tipo de archivo/multimedia</Label>
                                                        <Input
                                                            type="select"
                                                            name="media_type"
                                                            className="form-control"
                                                            value={formik.values.media_type}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            invalid={!!(formik.errors.media_type && formik.touched.media_type)}
                                                        >
                                                            <option value="">-- Seleccione el tipo de archivo multimedia --</option>
                                                            <option value="IMAGE">Imagen</option>
                                                            <option value="VIDEO">Video</option>
                                                            <option value="DOCUMENT">Documento</option>
                                                        </Input>
                                                        {formik.errors.media_type && formik.touched.media_type ? (
                                                            <FormFeedback type="invalid" className='d-block'>{formik.errors.media_type}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} md={6} className='mb-4 mt-2'>
                                                    <Label htmlFor="target" className="form-label">Archivo multimedia</Label>
                                                    <InputGroup>
                                                        <Input
                                                            innerRef={fileRef}
                                                            type="file"
                                                            name="file"
                                                            required={false}
                                                            className="form-control"
                                                            onBlur={formik.handleBlur}
                                                            onChange={(event: any) => {
                                                                formik.setFieldValue("file", event.target.files[0]);
                                                            }}
                                                            invalid={!!(formik.errors.file && formik.touched.file)}
                                                        />
                                                        <Button
                                                            color='danger'
                                                            onClick={(e) => _clearFile()}
                                                        >
                                                            <i className='mdi mdi-close'></i>
                                                        </Button>
                                                    </InputGroup>
                                                    {formik.errors.file && formik.touched.file ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.file}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </>}
                                        <Row>
                                            <FormikProvider value={formik}>
                                                <FieldArray
                                                    name="buttons"
                                                    render={({ insert, remove, push }) => (
                                                        <div>
                                                            {formik.values.buttons && formik.values.buttons.map((friend: any, index: any) => (
                                                                <div key={index}>
                                                                    <hr />
                                                                    <Field name={`buttons.${index}.type`} type="hidden" />
                                                                    <Row>
                                                                        <Col sm={6}>
                                                                            <Label>URL del botón</Label>
                                                                            <Row>
                                                                                <Col sm={8}>
                                                                                    <Field name={`buttons.${index}.target`} className="form-control mb-3" />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col sm={4}>
                                                                                    <button type="button" className='btn btn btn-light' onClick={() => insertAbandonedUrl(`buttons.${index}.target`, index)}>Agregar URL carrito</button>
                                                                                </Col>
                                                                                <Col sm={6}>
                                                                                    <button type="button" className='btn btn btn-light' onClick={() => insertTrackingUrl(`buttons.${index}.target`, index)}>Agregar URL de seguimiento de envío</button>
                                                                                </Col>
                                                                                <Col sm={6}>
                                                                                    <button type="button" className='btn btn btn-light' onClick={() => insertOrderUrl(`buttons.${index}.target`, index)}>Agregar URL de seguimiento de pedido</button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col sm={6} className='mt-2'>
                                                                            <Label>Texto del botón</Label>
                                                                            <Field name={`buttons.${index}.text`} className="form-control" />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col sm={6}>
                                                                            <button
                                                                                type="button"
                                                                                className='btn btn-sm btn-danger mt-2 mb-4'
                                                                                onClick={() => remove(index)}>
                                                                                <i className='mdi mdi-delete'></i> Eliminar botón
                                                                            </button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )
                                                            )}
                                                            {formik.values.buttons.length < 1 && <button type="button" className="btn btn-sm btn-success" onClick={() => push({
                                                                text: '',
                                                                target: '',
                                                                type: 'VISIT'
                                                            })}>
                                                                {/* show this when user has removed all buttons from the list */}
                                                                <i className="mdi mdi-plus"></i> Agregar botón
                                                            </button>}
                                                        </div>)}
                                                />
                                            </FormikProvider>
                                            <Col md={12}>
                                                <div className="text-end">
                                                    <button type="button" className="btn btn-light mr-2" onClick={() => navigate('/templates/')}>Cancelar</button>{' '}
                                                    <button type="submit" className="btn btn-primary" disabled={status === 'loading'}>
                                                        {status === 'loading' && <span className="d-flex align-items-center">
                                                            <span className="flex-grow-1 me-2">
                                                                Cargando...
                                                            </span>
                                                            <Spinner size="sm" className="flex-shrink-0" role="status"> Cargando... </Spinner>
                                                        </span>}{' '}
                                                        {status !== 'loading' && 'Crear mensaje'}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </React.Fragment >
    );
};

export default TemplateForm;
