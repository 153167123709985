import { createSlice } from "@reduxjs/toolkit";

export const initialState: any = {
  user: {},
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    authError(state: any, action: any) {
      state.error = action.payload;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action) {
      state.user = action.payload
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true
    },
    resetLoginFlag(state: any) {
      state.error = null
      state.loading = false;
      state.errorMsg = false;
    }
  },
});

export const {
  authError,
  loginSuccess,
  logoutUserSuccess,
  resetLoginFlag
} = loginSlice.actions

export default loginSlice.reducer;