//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  createUser,
} from "../../../helpers/backend_helper";

// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
} from "./reducer";
import { CreateUserRequest, UserStoreApproval } from 'types/Auth';

export const registerUser = (user: CreateUserRequest, storeApproval: UserStoreApproval) => async (dispatch: any) => {
  try {
    const data: any = await createUser(user, storeApproval);
    dispatch(registerUserSuccessful(data));
  } catch (error: any) {
    dispatch(
      registerUserFailed(error.response)
    );
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};
