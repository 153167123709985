import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    apiGetMessages,
} from "../../helpers/backend_helper";

export const getMessageList = createAsyncThunk("messages/getMessageList", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetMessages(storeId, params);
        return response;
    } catch (error) {
        return error;
    }
});
