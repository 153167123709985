import { createSelector } from "reselect";


export const selectCampaigns = createSelector(
    (state: any) => state.Campaign,
    (campaigns: any) => campaigns
)

export const selectProfile = createSelector(
    (state: any) => state.Profile,
    (profile: any) => profile
)

export const selectOrder = createSelector(
    (state: any) => state.Order,
    (order: any) => order
)

export const selectMessage = createSelector(
    (state: any) => state.Message,
    (message: any) => message
)

export const selectStore = createSelector(
    (state: any) => state.Store,
    (store: any) => store
)

export const selectTemplate = createSelector(
    (state: any) => state.Template,
    (template: any) => template
)

export const selectGroup = createSelector(
    (state: any) => state.Groups,
    (group: any) => group
)

export const selectReviews = createSelector(
    (state: any) => state.Reviews,
    (review: any) => review,
)

export const selectChat = createSelector(
    (state: any) => state.Chat,
    (chat: any) => chat
);