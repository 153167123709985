import DisplayDataLoading from 'Components/Common/DisplayDataLoading'
import React from 'react'
import { Row, Col } from 'reactstrap'


const CampaignGroupReportLoader = () => {
    return <React.Fragment>
        <Row>
            <Col xxl={12}>
                <DisplayDataLoading />
            </Col>
        </Row>
        <Row>
            <Col xxl={3}>
                <DisplayDataLoading />
            </Col>
            <Col xxl={3}>
                <DisplayDataLoading />
            </Col>
            <Col xxl={3}>
                <DisplayDataLoading />
            </Col>
            <Col xxl={3}>
                <DisplayDataLoading />
            </Col>
        </Row>
        <Row>
            <Col xxl={4}>
                <DisplayDataLoading />
            </Col>
            <Col xxl={4}>
                <DisplayDataLoading />
            </Col>
            <Col xxl={4}>
                <DisplayDataLoading />
            </Col>
        </Row>
    </React.Fragment>
}

export default CampaignGroupReportLoader

