import React, { useEffect, useRef } from 'react';

//import Components
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, UncontrolledAlert } from 'reactstrap';

import { useNavigate } from 'react-router-dom';

//Import Flatepicker
import TimezoneDropdown from 'Components/Common/TimezoneDropdown';
import TemplateDropdown from 'Components/Templates/TemplateDropdown';
import { Spanish } from "flatpickr/dist/l10n/es";
import { FieldArray, FormikProvider, useFormik } from 'formik';
import moment from 'moment-timezone';
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile, selectReviews, selectTemplate } from 'selectors';
import { getTemplates } from 'slices/templates/thunk';
import TemplateVariableDropdown from 'Components/Templates/TemplateVariableDropdown';


const ReviewConfigForm = ({
    onSubmit,
    config,
}: any) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const { currentStoreId } = useSelector(selectProfile)
    const reviews = useSelector(selectReviews)
    const dateRef = useRef<any>(null)


    useEffect(() => {
        if (currentStoreId) {
            dispatch(
                getTemplates({
                    storeId: currentStoreId,
                    all: true,
                })
            )
        }
    }, [currentStoreId])

    const refRating = useRef<HTMLTextAreaElement>(null)
    const refComment = useRef<HTMLTextAreaElement>(null)
    const refMedia = useRef<HTMLTextAreaElement>(null)
    const refThanks = useRef<HTMLTextAreaElement>(null)
    const refRejThanks = useRef<HTMLTextAreaElement>(null)

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            initial_message_id: config?.initial_message_id,
            rating_message: config?.rating_message || '',
            comment_message: config?.comment_message || '',
            media_message: config?.media_message || '',
            thanks_message: config?.thanks_message || '',
            thanks_rejection_message: config?.thanks_rejection_message || '',
            review_delay_days: config?.review_delay_days || 1,
            review_gap_days: config?.review_gap_days || 1,
            is_enabled: config?.is_enabled || false,
        },
        onSubmit: (values) => {
            onSubmit(values)
        },
    });


    const _clearDate = () => {
        if (dateRef.current) {
            dateRef.current.flatpickr.clear();
            formik.setFieldValue("scheduled_at", null)
            formik.setFieldValue("timezone", null)
        }
    }
    return (
        <React.Fragment>
            <Container fluid>
                {formik.values.target == 'FOLLOW_UP' && <UncontrolledAlert color="secondary" className="alert-success alert-dismissible bg-success text-white alert-label-icon fade show">
                    <i className="ri-chat-4-line label-icon"></i><strong>Campaña de seguimiento</strong> - Los envíos de seguimiento que tengan menos de 22 horas desde el primer mensaje, tendrán un costo reducido de 0.4 créditos! <i className='mdi mdi-party-popper'></i> <i className='mdi mdi-party-popper'></i> <i className='mdi mdi-party-popper'></i>
                </UncontrolledAlert>}
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <p className="text-muted">Desde aquí podrás crear una campaña para enviar notificaciones usando diferentes reglas o bien, importar un archivo con los contactos.</p>
                                <div className="live-preview">
                                    <Form onSubmit={(e: any) => {
                                        e.preventDefault();
                                        formik.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="initial_message_id" className="form-label">Plantilla de mensaje de apertura</Label>
                                                    <TemplateDropdown
                                                        type="select"
                                                        required
                                                        name="initial_message_id"
                                                        className="form-control"
                                                        value={formik.values.initial_message_id}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.initial_message_id && formik.touched.initial_message_id)}
                                                        selectLabel="-- Seleccione la plantilla de mensaje de apertura de encuesta --"
                                                        showPreviewButton
                                                    />
                                                    {formik.errors.initial_message_id && formik.touched.initial_message_id ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.initial_message_id}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="rating_message" className="form-label">Mensaje de Reseña</Label>
                                                    <Input
                                                        innerRef={refRating}
                                                        type="textarea"
                                                        name="rating_message"
                                                        rows="5"
                                                        className="form-control"
                                                        value={formik.values.rating_message}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.rating_message && formik.touched.rating_message)}
                                                    />
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refRating.current?.selectionStart
                                                            const nText = formik.values.rating_message.slice(0, position) + `{{${variable}}}` + formik.values.rating_message.slice(position)
                                                            formik.setFieldValue("rating_message", nText)
                                                        }} />
                                                    {formik.errors.rating_message && formik.touched.rating_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.rating_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="comment_message" className="form-label">Mensaje de comentario</Label>
                                                    <Input
                                                        type="textarea"
                                                        innerRef={refComment}
                                                        name="comment_message"
                                                        className="form-control"
                                                        rows="5"
                                                        value={formik.values.comment_message}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.comment_message && formik.touched.comment_message)}
                                                    />
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refComment.current?.selectionStart
                                                            const nText = formik.values.comment_message.slice(0, position) + `{{${variable}}}` + formik.values.comment_message.slice(position)
                                                            formik.setFieldValue("comment_message", nText)
                                                        }} />
                                                    {formik.errors.comment_message && formik.touched.comment_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.comment_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="media_message" className="form-label">Mensaje multimedia</Label>
                                                    <Input
                                                        type="textarea"
                                                        innerRef={refMedia}
                                                        name="media_message"
                                                        className="form-control"
                                                        rows="5"
                                                        value={formik.values.media_message}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.media_messge && formik.touched.media_messge)}
                                                    />
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refMedia.current?.selectionStart
                                                            const nText = formik.values.media_message.slice(0, position) + `{{${variable}}}` + formik.values.media_message.slice(position)
                                                            formik.setFieldValue("media_message", nText)
                                                        }} />
                                                    {formik.errors.media_message && formik.touched.media_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.media_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="thanks_message" className="form-label">Mensaje de agradecimiento</Label>
                                                    <Input
                                                        type="textarea"
                                                        rows="5"
                                                        innerRef={refThanks}
                                                        name="thanks_message"
                                                        className="form-control"
                                                        value={formik.values.thanks_message}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.thanks_message && formik.touched.thanks_message)}
                                                    />
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refThanks.current?.selectionStart
                                                            const nText = formik.values.thanks_message.slice(0, position) + `{{${variable}}}` + formik.values.thanks_message.slice(position)
                                                            formik.setFieldValue("thanks_message", nText)
                                                        }} />
                                                    {formik.errors.thanks_message && formik.touched.thanks_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.thanks_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="thanks_rejection_message" className="form-label">Mensaje de rechazo de reseña</Label>
                                                    <Input
                                                        type="textarea"
                                                        rows="5"
                                                        innerRef={refRejThanks}
                                                        name="thanks_rejection_message"
                                                        className="form-control"
                                                        value={formik.values.thanks_rejection_message}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.thanks_rejection_message && formik.touched.thanks_rejection_message)}
                                                    />
                                                    <TemplateVariableDropdown
                                                        options={[
                                                            { id: 'product', label: 'Producto' },
                                                            { id: 'first_name', label: 'Nombre' },
                                                            { id: 'order_number', label: 'Nro. de Pedido' },
                                                        ]}
                                                        onSelectVariable={(variable: string) => {
                                                            const position = refRejThanks.current?.selectionStart
                                                            const nText = formik.values.thanks_rejection_message.slice(0, position) + `{{${variable}}}` + formik.values.thanks_rejection_message.slice(position)
                                                            formik.setFieldValue("thanks_rejection_message", nText)
                                                        }} />
                                                    {formik.errors.thanks_rejection_message && formik.touched.thanks_rejection_message ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.thanks_rejection_message}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="review_delay_days" className="form-label">Enviar luego de (días)</Label>
                                                    <Input
                                                        type="number"
                                                        name="review_delay_days"
                                                        className="form-control"
                                                        multiple
                                                        value={formik.values.review_delay_days}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.review_delay_days && formik.touched.review_delay_days)}
                                                    />
                                                    {formik.errors.review_delay_days && formik.touched.review_delay_days ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.review_delay_days}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="review_gap_days" className="form-label">Tiempo entre encuestas (días)</Label>
                                                    <Input
                                                        type="number"
                                                        name="review_gap_days"
                                                        className="form-control"
                                                        multiple
                                                        value={formik.values.review_gap_days}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.review_gap_days && formik.touched.review_gap_days)}
                                                    />
                                                    {formik.errors.review_gap_days && formik.touched.review_gap_days ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.review_gap_days}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <FormGroup
                                                        check
                                                        inline
                                                    >
                                                        <Input
                                                            type="checkbox"
                                                            name="is_enabled"
                                                            checked={formik.values.is_enabled}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            invalid={!!(formik.errors.is_enabled && formik.touched.is_enabled)}
                                                        />
                                                        <Label htmlFor="review_delay_days" className="form-label">Está activo?</Label>
                                                    </FormGroup>
                                                    {formik.errors.is_enabled && formik.touched.is_enabled ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.is_enabled}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="text-end">
                                                    <button type="button" className="btn btn-light mr-2" onClick={() => navigate('/reviews/')}>Cancelar</button>{' '}
                                                    <button type="submit" className="btn btn-primary" disabled={reviews.status === 'loading'}>
                                                        {reviews.status === 'loading' && <span className="d-flex align-items-center">
                                                            <span className="flex-grow-1 me-2">
                                                                Cargando...
                                                            </span>
                                                            <Spinner size="sm" className="flex-shrink-0" role="status"> Cargando... </Spinner>
                                                        </span>}{' '}
                                                        {reviews.status !== 'loading' && 'Guardar'}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
};

export default ReviewConfigForm;
