import CampaignDropDown from 'Components/Campaigns/CampaignDropDown';
import CampaignStatusDropDown from 'Components/Campaigns/CampaignStatusDropDown';
import CampaignTable from 'Components/Campaigns/CampaignTable';
import BreadCrumb from 'Components/Common/BreadCrumb';
import Pagination from 'Components/Common/Pagination';
import OrderTable from 'Components/Orders/OrderTable';
import ReviewTable from 'Components/Reviews/ReviewTable';
import { reviews } from 'common/data';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { selectCampaigns, selectOrder, selectProfile, selectReviews } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import { getOrderList } from 'slices/order/thunk';
import { getReviewList, updateReview } from 'slices/reviews/thunk';


const ReviewList = () => {
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const [campaignId, setCampaignId] = useState<any>(null)
    const [status, setStatus] = useState<any>(null)
    const campaigns = useSelector(selectCampaigns)
    const reviews = useSelector(selectReviews)

    const fetchPage = useCallback((page: any) => {
        if (currentStoreId) {
            dispatch(
                getReviewList({
                    storeId: currentStoreId,
                    skip: page,
                })
            )
        }
    }, [currentStoreId])

    const onUpdateReview = useCallback((revId: number, isPublic: boolean) => {
        dispatch(
            updateReview({
                storeId: currentStoreId,
                reviewId: revId,
                isPublic
            })
        )
    }, [currentStoreId])

    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
        }
    }, [currentStoreId]);


    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
        }
    }, [currentStoreId]);

    document.title = 'Ver Reseñas | Burbuxa'

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Listado de Reseñas" pageTitle="Reseñas" />

                <Container fluid>
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <CardBody className="p-0">
                                    <ReviewTable
                                        update={(reviewId, isPublic) => onUpdateReview(reviewId, isPublic)}
                                        loading={reviews.status == 'loading'}
                                        reviews={reviews.items}
                                        showTitle={false}
                                    />
                                    <Pagination
                                        total={reviews.limit !== 0 ? Math.ceil(reviews.total / reviews.limit) : 0}
                                        data={reviews.items}
                                        setCurrentPage={(page: any) => fetchPage((page - 1) * reviews.limit)}
                                        currentPage={reviews.limit !== 0 ? Math.ceil(reviews.skip / reviews.limit) + 1 : 0}
                                        perPageData={reviews.limit}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    </React.Fragment>
}

export default ReviewList 
