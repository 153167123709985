import BreadCrumb from 'Components/Common/BreadCrumb';
import ReviewItem from 'Components/Reviews/ReviewItem';
import ReviewTopTable from 'Components/Reviews/ReviewTopTable';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { selectCampaigns, selectProfile, selectReviews } from 'selectors';
import { getReviewList, getReviewTopList, updateReview } from 'slices/reviews/thunk';


const ReviewDashboard = () => {
    const dispatch: any = useDispatch()
    const navigate = useNavigate()
    const { currentStoreId } = useSelector(selectProfile)
    const reviews = useSelector(selectReviews)

    const fetchPage = useCallback((page: any) => {
        if (currentStoreId) {
            dispatch(
                getReviewList({
                    storeId: currentStoreId,
                    skip: page,
                })
            )
        }
    }, [currentStoreId])

    const onUpdateReview = useCallback((revId: number, isPublic: boolean) => {
        dispatch(
            updateReview({
                storeId: currentStoreId,
                reviewId: revId,
                isPublic
            })
        )
    }, [currentStoreId])

    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
            dispatch(
                getReviewTopList({
                    storeId: currentStoreId,
                })
            )
        }
    }, [currentStoreId]);

    document.title = 'Reseñas | Burbuxa'

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Reseñas" pageTitle="Reseñas" />

                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Top 5 de Productos</h4>
                                </CardHeader>
                                <CardBody className="p-0">
                                    <ReviewTopTable
                                        loading={reviews.status === 'loading'}
                                        reviews={reviews.topItems}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Últimas Reseñas</h4>
                                </CardHeader>
                                <CardBody className="p-0">
                                    {reviews
                                        .items
                                        .map((each: any, index: number) => {
                                            return <ReviewItem
                                                item={each}
                                                key={index}
                                                update={onUpdateReview}
                                            />
                                        })
                                    }
                                    <div className='text-center m-2'>
                                        <Button
                                            color='info'
                                            onClick={() => navigate('/reviews/list')}>
                                            Ver más
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    </React.Fragment>
}

export default ReviewDashboard
