import DisplayDataCard from 'Components/Common/DisplayDataCard'
import { Col, Container, Row } from 'reactstrap'


const CampaignTransactionalMetrics = ({ report }: any) => {

    return <Container fluid>
        <Row>
            <Col>
                <div className="h-100">
                    <Row>
                        <Col sm={6} md={3}>
                            <DisplayDataCard
                                value={report?.totalMessagesSent}
                                label='Mensajes enviados'
                                bgIconColor="danger"
                                icon="bx bx-message-dots"
                            />
                        </Col>
                        <Col sm={6} md={3}>
                            <DisplayDataCard
                                value={report?.totalInvestment}
                                label='Inversión'
                                prefix='USD '
                                bgIconColor="warning"
                                icon="bx bx-money"
                            />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    </Container>
}

export default CampaignTransactionalMetrics