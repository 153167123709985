import { Navigate } from "react-router-dom";

//Dashboard


//Calendar
// Email box

//Chat

// Project

//Task

//Transactions

//Crm Pages

//Invoices

// Support Tickets

// //Ecommerce Pages

// NFT Marketplace Pages

// Base Ui

// Advance Ui

// Widgets

//Forms

//Tables

//Icon pages

//Maps

//AuthenticationInner pages
//pages



//APi Key

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

//Charts

//Job pages

// Landing Index


// User Profile

import CampaignCreate from "pages/Campaigns/CampaignCreate";
import CampaignDashboard from "pages/Campaigns/CampaignDashboard";
import CampaignEdit from "pages/Campaigns/CampaignEdit";
import CampaignList from "pages/Campaigns/CampaignList";
import OrderList from "pages/Orders/OrderList";
import MessageList from "pages/Messages/MessageList";
import TemplateCreate from "pages/Templates/TemplateCreate";
import TemplateList from "pages/Templates/TemplateList";
import ReviewList from "pages/Reviews/ReviewList";
import ReviewConfig from "pages/Reviews/ReviewConfig.tsx";
import ReviewDashboard from "pages/Reviews/Dashboard";
import Chat from "pages/Chat/index";

const authProtectedRoutes = [
  { path: "/dashboard", component: <CampaignDashboard /> },
  { path: "/campaigns", component: <CampaignList /> },
  { path: "/orders", component: <OrderList /> },
  { path: "/messages", component: <MessageList /> },
  { path: "/campaigns/new", component: <CampaignCreate /> },
  { path: "/templates/new", component: <TemplateCreate /> },
  { path: "/campaigns/edit/:id", component: <CampaignEdit /> },
  { path: "/campañas/dashboard", component: <CampaignDashboard /> },
  { path: "/reviews/dashboard", component: <ReviewDashboard /> },
  { path: "/reviews/list", component: <ReviewList /> },
  { path: "/reviews/config", component: <ReviewConfig /> },
  { path: "/templates", component: <TemplateList /> },
  { path: "/chat", component: <Chat /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes: any = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },


];

export { authProtectedRoutes, publicRoutes };
