import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { formatDateDifference, formatNumber } from 'helpers/format';
import { format } from 'date-fns';

interface MessageTableProps {
    messages?: any[],
    title?: string;
    showTitle?: boolean;
    loading?: boolean;
}

const StatusName: { [key: string]: string } = {
    SENT: 'Enviado',
    FAILED: 'Fallido',
    READ: 'Enviado',
    DELIVERED: 'Enviado',
};

const StatusColor: { [key: string]: string } = {
    SENT: 'success',
    READ: 'success',
    DELIVERED: 'success',
    FAILED: 'danger',
};

const MessageTable = ({ messages, title, showTitle = true, loading = false }: MessageTableProps) => {
    return (
        <div className="table-responsive">
            <table className="table table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                    <tr>
                        <th scope="col" className="text-center">Número de teléfono</th>
                        <th scope="col" className="text-center">Estado de mensaje</th>
                        <th scope="col" className="text-center">Fecha de envío</th>
                    </tr>
                </thead>
                <tbody>
                    {(messages == null || loading) &&
                        <>
                            {Array(5).fill(0).map((_, index) => <tr key={index}>
                                {Array(3).fill(0).map((_, sIndex) => <td key={sIndex}>
                                    <p className="card-text placeholder-glow">
                                        <span className="placeholder col-12"></span>
                                    </p>
                                </td>)}
                            </tr>)}
                        </>
                    }
                    {(messages || []).map((item, key) => {
                        
                        return (
                            <tr key={key}>
                                <td className="text-center text-primary">
                                    {item.to}
                                </td>
                                <td className="text-center">
                                    <span className={"badge bg-" + StatusColor[item.status] + "-subtle fs-12 text-" + StatusColor[item.status]}>{StatusName[item.status]}</span>
                                </td>
                                <td className="text-center">
                                    <span className="text-dark">{format(new Date(item.sent_at), 'dd/MM/yyyy')}</span>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default MessageTable;
