import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    GetCampaignRequest,
    apiGetCampaigns,
    apiGetStoreReport,
    getOrderList as getOrderListApi,
    apiGetCampaignDistributionReport,
    apiGetCampaignById,
    apiPostCampaignBudget,
    apiPostCampaign,
    apiPostCampaignFile,
    apiPatchCampaign,
    apiPostCampaignPause,
    apiPostCampaignResume,
    apiDeleteCampaign,
    apiGetReport
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";

export const getCampaigns = createAsyncThunk("campaigns/getCampaigns", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetCampaigns(storeId, params);
        return response;
    } catch (error) {
        return error;
    }
});

export const getCampaignById = createAsyncThunk("campaigns/getCampaignById", async ({ storeId, campaignId }: any) => {
    try {
        const response = apiGetCampaignById(storeId, campaignId);
        return response;
    } catch (error) {
        return error;
    }
});

export const getReport = createAsyncThunk("campaigns/getReport", async ({ storeId, campaignId, groupId, ...params }: any) => {
    try {
        const response = apiGetReport(storeId, campaignId, groupId, params);
        return response;
    } catch (error) {
        return error;
    }
});

export const getStoreReport = createAsyncThunk("campaigns/getStoreReport", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetStoreReport(storeId, params);
        return response;
    } catch (error) {
        return error;
    }
});

export const getOrderList = createAsyncThunk("campaigns/getOrderList", async () => {
    try {
        const response = getOrderListApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const getCampaignDistribution = createAsyncThunk("campaigns/getCampaignDistribution", async ({
    storeId,
    campaignId,
    fromDate,
    toDate,
}: any) => {
    try {
        const response = apiGetCampaignDistributionReport(storeId, campaignId, {
            from_date: fromDate,
            to_date: toDate,
        });
        return response;
    } catch (error) {
        return error;
    }
});

export const createCampaignBudget = createAsyncThunk("campaigns/createCampaignBudget", async ({
    storeId,
    payload,
}: any) => {
    try {
        const response = apiPostCampaignBudget(storeId, payload);
        return response
    } catch (error) {
        return error
    }
})

export const createCampaign = createAsyncThunk("campaigns/createCampaign", async ({
    storeId,
    payload,
    file,
}: any) => {
    try {

        if (payload['frequency']) {
            payload['rule_params']['frequency'] = payload['frequency']
        }

        const response = apiPostCampaign(storeId, payload);

        return new Promise(async (resolve, reject) => {
            const data = await response;
            if (file) {
                apiPostCampaignFile(storeId, data.id, file)
                    .then((data: any) => {
                        toast("Campaña creada con éxito!", { position: "top-right", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' })

                        resolve(data)
                    })
                    .catch((e: any) => reject(e))
            } else {
                toast("Campaña creada con éxito!", { position: "top-right", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' })
                resolve(data)
            }
        })
    } catch (error) {
        return error
    }
})

export const updateCampaign = createAsyncThunk("campaigns/updateCampaign", async ({
    storeId,
    campaignId,
    payload,
    file,
}: any) => {
    try {
        const response = apiPatchCampaign(storeId, campaignId, payload);
        if (file) {
            return new Promise(async (resolve, reject) => {
                const data = await response;
                apiPostCampaignFile(storeId, data.id, file)
                    .then((data: any) => {
                        toast("Campaña actualizada con éxito!", { position: "top-right", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' })
                        window.location.href = '/campaigns/'
                        resolve(data)
                    })
                    .catch((e: any) => reject(e))
            })
        } else {
            return response
        }
    } catch (error) {
        return error
    }
})

export const pauseCampaign = createAsyncThunk("campaigns/pauseCampaign", async ({
    storeId,
    campaignId,
}: any) => {
    try {
        const response = apiPostCampaignPause(storeId, campaignId);
        response.then((r: any) => {
            toast(`Campaña "${r.name}" pausada`, {
                position: "top-right",
                hideProgressBar: false,
                className: 'bg-warning text-white',
                progress: undefined,
                toastId: ""
            })

            return r
        })
        return response;
    } catch (error) {
        return error;
    }
})

export const deleteCampaign = createAsyncThunk("campaigns/deleteCampaign", async ({
    storeId,
    campaignId,
}: any) => {
    try {
        const response = apiDeleteCampaign(storeId, campaignId);
        response.then((r: any) => {
            toast(`Campaña "${r.name}" eliminada`, {
                position: "top-right",
                hideProgressBar: false,
                className: 'bg-success text-white',
                progress: undefined,
                toastId: ""
            })

            return r
        })
        return response;
    } catch (error) {
        return error;
    }
})

export const resumeCampaign = createAsyncThunk("campaigns/resumeCampaign", async ({
    storeId,
    campaignId,
}: any) => {
    try {
        const response = apiPostCampaignResume(storeId, campaignId);
        response.then((r: any) => {
            toast(`Campaña "${r.name}" activada`, {
                position: "top-right",
                hideProgressBar: false,
                className: 'bg-success text-white',
                progress: undefined,
                toastId: ""
            })

            return r
        })
        return response;
    } catch (error) {
        return error;
    }
});