import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import { formatDateDifference, formatNumber } from 'helpers/format';
import { format } from 'date-fns';
import './ReviewTable.css';


interface ReviewTableProps {
    reviews?: any[],
    title?: string,
    showTitle?: boolean,
    loading?: boolean,
    update: (reviewId: number, isPublic: boolean) => void,
}


const ReviewTable = ({ reviews, title, showTitle = true, loading = false, update }: ReviewTableProps) => {
    return (
        <div className="table-responsive">
            <table className="table table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                    <tr>
                        <th scope="col" className="text-center">Multimedia</th>
                        <th scope="col" className="text-center">Fecha</th>
                        <th scope="col" className="text-center">Cliente</th>
                        <th scope="col" className="text-center">Teléfono</th>
                        <th scope="col" className="text-center">Pedido</th>
                        <th scope="col" className="text-center">Rating</th>
                        <th scope="col" className="text-center">Producto</th>
                        <th scope="col" className="text-center">Estado</th>
                        <th scope="col" className="text-center">Comentario</th>
                        <th scope="col" className="text-center">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {(reviews == null || loading) &&
                        <>
                            {Array(5).fill(0).map((_, index) => <tr key={index}>
                                {Array(8).fill(0).map((_, sIndex) => <td key={sIndex}>
                                    <p className="card-text placeholder-glow">
                                        <span className="placeholder col-12"></span>
                                    </p>
                                </td>)}
                            </tr>)}
                        </>
                    }
                    {(reviews || []).map((item, key) => {

                        return (
                            <tr key={key} style={{
                                backgroundColor: !item.is_public ? '#f7666e42' : ''
                            }}>
                                <td className='media-content text-center'>
                                    {item.type === 'IMAGE' && <img src={item.media} />}
                                    {item.type === 'VIDEO' &&
                                        <video controls>
                                            <source src={item.media} type={item.content_type} />
                                        </video>
                                    }
                                </td>
                                <td className="text-center text-primary">
                                    <span className="text-dark">{item.created_at
                                        ? format(new Date(item.created_at), 'dd/MM/yyyy')
                                        : '-'}
                                    </span>
                                </td>
                                <td className="text-center text-primary">
                                    {item.name}
                                </td>
                                <td className="text-center text-primary">
                                    {item.phone}
                                </td>
                                <th scope="col" className="text-center">
                                    <span className="text-dark">{item.order_number}</span>
                                </th>
                                <td className="text-left">
                                    <span className="text-dark">
                                        {'⭐️'.repeat(item.rate)} ({item.rate} / 5)
                                    </span>
                                </td>
                                <td className="text-center">
                                    <span className="text-dark">{item.product_name}
                                    </span>
                                </td>
                                <td className="text-center" style={{ fontWeight: 'bold' }}>
                                    {!item.is_public &&
                                        <span className="text-danger">
                                            <span className='mdi mdi-checkbox-blank-circle-outline'></span>{' '}
                                            Oculta
                                        </span>}
                                    {item.is_public &&
                                        <span className="text-success">
                                            <span className='mdi mdi-check-circle-outline'></span>{' '}
                                            Publicada
                                        </span>}
                                </td>
                                <td className="text-left">
                                    <span className="text-dark comment">{item.comment}</span>
                                </td>
                                <td className="text-center">
                                    {!item.is_public && <Button color='success' size='sm' onClick={() => update(item.id, true)}>
                                        <i className="mdi mdi-publish"></i> Publicar
                                    </Button>}
                                    {item.is_public && <Button color='danger' size='sm' onClick={() => update(item.id, false)}>
                                        <i className="mdi mdi-close"></i> Ocultar
                                    </Button>}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ReviewTable;
