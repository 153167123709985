import { format } from 'date-fns';


interface ReviewTopTableProps {
    reviews?: any[],
    loading?: boolean,
}


const ReviewTopTable = ({ reviews, loading = false }: ReviewTopTableProps) => {
    return (
        <div className="table-responsive">
            <table className="table table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                    <tr>
                        <th scope="col" className="text-center">Últ. Reseña</th>
                        <th scope="col" className="text-center">Producto</th>
                        <th scope="col" className="text-center">Cantidad</th>
                        <th scope="col" className="text-center">Calif. Promedio</th>
                    </tr>
                </thead>
                <tbody>
                    {(reviews == null || loading) &&
                        <>
                            {Array(4).fill(0).map((_, index) => <tr key={index}>
                                {Array(8).fill(0).map((_, sIndex) => <td key={sIndex}>
                                    <p className="card-text placeholder-glow">
                                        <span className="placeholder col-12"></span>
                                    </p>
                                </td>)}
                            </tr>)}
                        </>
                    }
                    {(reviews || []).map((item, key) => {

                        return (
                            <tr key={key}>
                                <td className="text-center text-primary">
                                    <span className="text-dark">{item.last_review_date
                                        ? format(new Date(item.last_review_date), 'dd/MM/yyyy')
                                        : '-'}
                                    </span>
                                </td>
                                <td className="text-center text-primary">
                                    {item.product_name}
                                </td>
                                <th scope="col" className="text-center">
                                    <span className="text-dark">{item.count}</span>
                                </th>
                                <td className="text-left">
                                    <span className="text-dark">
                                        {'⭐️'.repeat(Math.round(item.avg))} ({Math.round(item.avg)} / 5)
                                    </span>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ReviewTopTable;
