import Select from 'react-select'
import TIMEZONES from '../../common/timezones'


const TimezoneDropdown = ({
    value,
    onChange,
    ...props
}: {
    value: any,
    onChange: (e: any) => void,
    name?: any,
    id?: any,
    className?: string,
    placeholder?: string,
    required?: boolean,
}) => {
    return <Select
        value={value}
        onChange={onChange}
        options={TIMEZONES.map((each) => ({
            label: each,
            value: each,
        }))}
        {...props}
    ></Select>
}

export default TimezoneDropdown