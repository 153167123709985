//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import authClient, { audience, dbConnection } from 'helpers/auth0/auth0'
import { loginSuccess, logoutUserSuccess, authError, resetLoginFlag as apiResetLogin } from './reducer';
import authService from "helpers/auth0/auth.service";
import { LoginData, UserStoreApproval } from "types/Auth";

// const fireBaseBackend = getFirebaseBackend();

export const loginUser = (user: any, history: any, storeApprovalData: UserStoreApproval) => async (dispatch: any) => {
  try {
    const authPromise = new Promise((resolve, reject) => {
      authClient.client.login(
        {
          realm: dbConnection,
          username: user.email,
          password: user.password,
          scope: 'offline_access',
          audience: audience,
        },
        (error: any, result: any) => {
          if (error !== null) {
            reject(error)
          } else {
            authService.onLogin(result as LoginData)
            console.log(result)
            resolve(result)
          }
        }
      )
    });
    const response = await authPromise;
    const data = await response;

    if (storeApprovalData) {
      const filteredParams = Object.entries(storeApprovalData)
        .filter(([_, value]) => value !== undefined)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: String(value) }), {});

      const searchParams = new URLSearchParams(filteredParams).toString();
      const url = searchParams ? `/dashboard?${searchParams}` : '/dashboard';
      history(url);
    } else {
      history('/dashboard');
    }
  } catch (error: any) {
    const msg = "Error, credenciales de acceso incorrectas"
    dispatch(authError(msg as any))
  }
};

export const logoutUser = () => async (dispatch: any) => {
  authService.logout();
  dispatch(logoutUserSuccess(null));
};

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(apiResetLogin());
    return response;
  } catch (error: any) {
    dispatch(authError(error));
  }
};