import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    apiGetOrders,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";

export const getOrderList = createAsyncThunk("orders/getOrderList", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetOrders(storeId, params);
        return response;
    } catch (error) {
        return error;
    }
});
