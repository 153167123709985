import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetConversations,
  apiGetConversationById,
  apiPutConversation,
  apiPostMessage,
  apiGetConversationMessages,
  apiPostAssignConversation,
  apiPostIncomingMessage,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";

// Fetch list of conversations
export const getConversations = createAsyncThunk(
  "chat/getConversations",
  async ({ storeId, status, page, perPage }: any, { rejectWithValue }) => {
    try {
      const response = await apiGetConversations(storeId, status, page, perPage);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch a conversation by ID
export const getConversationById = createAsyncThunk(
  "chat/getConversationById",
  async ({ storeId, conversationId }: any, { rejectWithValue }) => {
    try {
      const response = await apiGetConversationById(storeId, conversationId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update a conversation
export const updateConversation = createAsyncThunk(
  "chat/updateConversation",
  async ({ storeId, conversationId, payload }: any, { rejectWithValue }) => {
    try {
      const response = await apiPutConversation(storeId, conversationId, payload);
      toast("Conversation updated successfully!", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
      return response;
    } catch (error: any) {
      toast("Failed to update conversation.", {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-danger text-white",
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Send a message in a conversation
export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async ({ storeId, conversationId, payload }: any, { rejectWithValue }) => {
    try {
      const response = await apiPostMessage(storeId, conversationId, payload);
      toast("Message sent successfully!", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
      return response;
    } catch (error: any) {
      toast("Failed to send message.", {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-danger text-white",
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Get messages from a conversation
export const getConversationMessages = createAsyncThunk(
  "chat/getConversationMessages",
  async ({ storeId, conversationId, page, perPage }: any, { rejectWithValue }) => {
    try {
      const response = await apiGetConversationMessages(storeId, conversationId, page, perPage);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Assign a conversation to a user
export const assignConversation = createAsyncThunk(
  "chat/assignConversation",
  async ({ storeId, conversationId, payload }: any, { rejectWithValue }) => {
    try {
      const response = await apiPostAssignConversation(storeId, conversationId, payload);
      toast("Conversation assigned successfully!", {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
      return response;
    } catch (error: any) {
      toast("Failed to assign conversation.", {
        position: "top-right",
        hideProgressBar: false,
        className: "bg-danger text-white",
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Handle incoming messages from webhook
export const receiveIncomingMessage = createAsyncThunk(
  "chat/receiveIncomingMessage",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await apiPostIncomingMessage(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
