import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import DisplayDataLoading from 'Components/Common/DisplayDataLoading'


const StoreMetricsLoading = () => {
    return <React.Fragment>
        <Container fluid>
            <Row>
                <Col sm={6} md={3}>
                    <DisplayDataLoading />
                </Col>
                <Col sm={6} md={3}>
                    <DisplayDataLoading />
                </Col>
                <Col sm={6} md={3}>
                    <DisplayDataLoading />
                </Col>
                <Col sm={6} md={3}>
                    <DisplayDataLoading />
                </Col>
                <Col sm={6} md={4}>
                    <DisplayDataLoading />
                </Col>
                <Col sm={6} md={4}>
                    <DisplayDataLoading />
                </Col>
                <Col sm={6} md={4}>
                    <DisplayDataLoading />
                </Col>
                <Col sm={12}>
                    <DisplayDataLoading />
                </Col>
            </Row>
        </Container>
    </React.Fragment>

}

export default StoreMetricsLoading