
import React from 'react'


const CampaignStatus = ({ status }: any) => {
    switch (status) {
        case "ACTIVE":
            return <span className="badge bg-success-subtle  text-success text-uppercase">Activa</span>;
        case "SCHEDULED":
            return <span className="badge bg-primary-subtle  text-success text-uppercase">Programada</span>;
        case "INACTIVE":
            return <span className="badge bg-danger-subtle  text-danger text-uppercase">Inactiva</span>;
        case "PAUSED":
            return <span className="badge bg-warning-subtle text-warning  text-uppercase">Pausada</span>;
    }
}


export default CampaignStatus