import COUNTRIES from "common/countries"
import { Field, useFormik } from "formik"
import React, { useEffect } from "react"
import ReactFlagsSelect from "react-flags-select"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { Button, Col, Form, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { selectProfile, selectStore, selectTemplate } from "selectors"
import { getCountries } from "slices/stores/thunk"
import { sendMessageTest } from "slices/templates/thunk"


const TemplatePreviewModal = ({
    show,
    toggle,
    onSend,
    templateId
}: any) => {
    const dispatch: any = useDispatch()
    const store = useSelector(selectStore)
    const profile = useSelector(selectProfile)
    const message = useSelector(selectTemplate)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            country: '',
            phone: '',
        },
        onSubmit: (values) => {
            const country = store.countries.find((each: any) => each.iso_code === values.country);
            dispatch(
                sendMessageTest({
                    storeId: profile.currentStoreId,
                    countryId: country.id,
                    to: values.phone,
                    templateId,
                })
            )
        }
    })

    useEffect(() => {
        dispatch(
            getCountries()
        )
    }, [])

    const selectCountryLabels: any = {}
    COUNTRIES.map((each: any) => {
        selectCountryLabels[each.code] = {
            primary: each.code,
            secondary: each.dial_code
        }
    })

    return <React.Fragment>
        {show && <Modal
            isOpen={show}
            toggle={toggle}
            centered={true}
            backdrop
        >
            <Form onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit()
            }}>
                <ModalHeader toggle={toggle}>Enviar mensaje de prueba
                </ModalHeader>
                <ModalBody className="">
                    <Row>
                        <Col xs={12}>
                            <Label htmlFor="target" className="form-label">Teléfono de destino</Label>
                            <InputGroup>
                                <label onClick={e => e.stopPropagation()} style={{
                                    marginBottom: 0,
                                    lineHeight: 1.2,
                                    width: 150,
                                }}>
                                    <ReactFlagsSelect
                                        className="p-0 mr-1"
                                        selectButtonClassName="form-control"
                                        selectedSize={14}
                                        optionsSize={12}
                                        searchable
                                        showSecondaryOptionLabel
                                        customLabels={selectCountryLabels}
                                        placeholder="País"
                                        showOptionLabel
                                        showSecondarySelectedLabel
                                        showSelectedLabel={false}
                                        searchPlaceholder="Buscar país"
                                        selected={formik.values.country}
                                        onSelect={(v) => {
                                            formik.setFieldValue("country", v)
                                        }}
                                    />
                                </label>
                                <Input
                                    style={{
                                        marginLeft: 10,
                                    }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="text"
                                    name="phone"
                                    className="form-control"
                                    placeholder="Ingrese número de teléfono"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 justify-content-end">
                        <Button
                            type="button"
                            onClick={(e) => formik.handleSubmit()}
                            color="success"
                            disabled={message.messageTestStatus === 'sending'}
                        >
                            <i className="bx bx-mail-send fs-20 align-middle"></i>{' '}
                            {message.messageTestStatus === 'sending' ? 'Enviando...' : 'Enviar'}
                        </Button>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>}
    </React.Fragment>
}

export default TemplatePreviewModal